// Contains all strings for the entire website

export default {
  home: {
    picture: {
      caption:
        'The woods are lovely dark and deep. But I have promises to keep. And miles to go before I sleep. And miles to go before I sleep.',
      author: 'Robert Frost, Stopping By Woods on a Snowy Evening.',
    },
    content: [
      'Throughout my career as a writer in various capacities and industries, I was always most affected by all the personal stories that lie within each one of us.  Not so much the “what was my biggest accomplishment,” but rather, “what was the underlying story that propelled me to do what I did.” Everyone has them, if you look. What did I learn?',
      'In between the cracks of the ordinary there appeared to be a plethora of fascinating stories.',
      'The fact is, an awful lot of people have these type of stories – if you know how to dig beneath the surface. As I conducted interviews over the years I found myself enjoying the “how” and “why” of so many lives moving in so many different directions. People with interesting insights to contribute, lives replete with successes and scars. Compelling conversations turned into copy.',
      'What makes a person tick, an organization thrive, an idea take hold and mushroom into something greater... discovering how passion catches fire? Every person with a growing business, a new discovery, or a mission deserves to have their voice heard. It can make for very captivating “listening.” As a writer and podcaster, there is no greater satisfaction than taking the ordinary, and extracting the extraordinary.',
      'And that is what Positively ML is all about.',
    ],
  },
  about: {
    content: [
      '<b>Mary-Lauri Bartola (ML)</b> is has been a communicator for many years, leading creative teams as well as writing for large and small corporations, many in the healthcare and financial services, non-profit, educational and start-up sectors.',
      'Beginning her career as a journalist, Mary-Lauri was a newspaper reporter and anchored the television news for a small cable TV affiliate. She went on to a career in healthcare public relations, where she ran the department at one of the most  prestigious hospitals in New York City. During that time she hosted international press conferences and helped publicize emerging technologies and exciting medical breakthroughs.',
      "Working in the corporate sector, Mary-Lauri oversaw employee communications for one of the nation's largest financial services and insurance companies and also consulted for numerous healthcare and pharmaceutical organizations. She was instrumental in utilizing technology platforms to help executives communicate more effectively and easily, and to help employees learn what the heck is going on.",
      'Mary-Lauri likes using her eclectic talents to make communications more fun. Beyond blogging, she enjoys working on her podcast series.  A recipient of numerous awards for writing and communications, she genuinely enjoys making people look good and sound good.  She is dedicated to the idea that "saying it right means everything." and is on a mission to unearth all the interesting, wonderful and unique ideas that keep the world spinning... in a good way.',
    ],
  },
  consulting: {
    heading: 'You Don’t Have to Break the Law to Get Your Child into College',
    content: [
      'The college application process is a journey. And that journey doesn’t involve bribes or schemes. As a coach, my goal is to help simplify the process, assuring all steps are completed while at the same time making the student as attractive as possible to his/her chosen schools. Here are some key objectives working with your student:',
      '<li>Help the student <b>choose the optimal challenging course load</b> while developing a unique extracurricular profile</li>',
      '<li><b>Create a strategy</b> for college applications</li>',
      '<li><b>Research and make recommendations for colleges</b> that fit the student’s career interests, lifestyle and family</li>',
      '<li>Support the student in <b>selecting a high-impact service project</b> that will help them stand out among their peers during admissions season</li>',
      '<li>Discuss and help organize the college essay</li>',
      '<li><b>Role play</b> college interviews and presentation techniques</li>',
      'In addition, we will make suggestions about potential scholarship opportunities, help the student select a relevant summer program and/or internship, and suggest extracurricular activities that align with student’s interests and passions that will boost attractiveness to admissions departments.',
      "We will take the mystery out of the admissions process, help your student build connections with teachers and community leaders who can eventually write compelling recommendation letters, and support the student whenever they're feeling stressed or under pressure.",
      'Finally, helping your student create/organize/whiteboard important deadlines will be critical for success.',
      'The world has changed for so many college students. Online classes are now more prevalent; testing scores may not even apply. Get real world guidance when you need it the most. We are always available to help strategize your college plan. For an immediate cost-free "guidance" session, contact us at mlbartola@comcast.net.',
    ],
  },
  empower: {
    heading: 'Finding Inspiration in the World Around Us',
    body: [
      'Welcoming stories that inspire from writers of all kinds.',
      'Send your enquiries/stories to <a href="mailto:mary@positivelyml.com">mary@positivelyml.com</a>!',
    ],
    quote: {
      caption:
        'If you look the right way, you can see that the whole world is a garden.',
      author: 'The Secret Garden, Frances Hodgson Burnett',
    },
  },
  footer: {
    aboutML:
      '<b>Mary-Lauri Bartola (ML)</b> has been a communicator for many years, leading creative teams as well as writing for large and small corporations, many in the healthcare and financial services, non-profit, educational and start-up sectors. Mary-Lauri likes using her eclectic talents to make communications more fun. A recipient of numerous awards for writing and communications, she genuinely enjoys making people look good and sound good. She is dedicated to the idea that "saying it right means everything" and is on a mission to unearth all the interesting, wonderful and unique ideas that keep the world spinning... in a good way.',
    socialIcons: {
      icons: [
        {
          className: 'fab fa-facebook-f',
          label: 'PositivelyML',
          href: 'https://www.facebook.com/PositivelyML',
        },
        {
          className: 'fab fa-twitter',
          label: '@MaryLauri1',
          href: 'https://twitter.com/marylauri1',
        },
        {
          className: 'fas fa-envelope',
          label: 'mary@positivelyml.com',
          href: 'mailto:mary@positivelyml.com',
        },
      ],
    },
  },
  sidebar: {
    about: 'My name is Mary-Lauri and I like to blog about things sometimes!',
  },
  placeholderArticle: {
    title: 'Content Unavailable',
    content:
      '<p>This page is currently being worked on. Try coming back later!</p>',
  },
};
