const DEBOUNCE_MS = 700;

export default (fn: (...args: any[]) => void, debounceTime = DEBOUNCE_MS) => {
  let timerId;

  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), debounceTime);
  };
};
