export default class AuthAPI {
  static fetchAuth = () => {
    return fetch('/api/v1/auth');
  };

  static postAuth = ({ username, password }) => {
    return fetch('/api/v1/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
  };
}
