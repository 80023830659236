export enum RequestTypes {
  REQUEST = 'REQUEST',
  RECEIVE = 'RECEIVE',
  FAILURE = 'FAILURE',
}

export enum RequestStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export const makeRequestActionTypes = (requestType, namespace = '') => {
  const requestName = namespace ? `${namespace}/${requestType}` : requestType;

  return {
    REQUEST: `${requestName}_REQUESTED`,
    RECEIVE: `${requestName}_RECEIVED`,
    FAILURE: `${requestName}_FAILURE`,
    RESET: `${requestName}_RESET`,
  };
};
