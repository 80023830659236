import * as React from 'react';

interface HeaderProps {
  text: string;
}

class Header extends React.Component<HeaderProps, {}> {
  static defaultProps: HeaderProps;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header onClick={() => (window.location.href = '/')}>
        <div id="header-title">{this.props.text}</div>
      </header>
    );
  }
}

Header.defaultProps = {
  text: ''
};

export default Header;
