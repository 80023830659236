import * as React from 'react';
import Flex from '../box/Flex';

import PlayerBar from './PlayerBar';
import PlayerButton from './PlayerButton';
import SkipButton from './SkipButton';

interface AudioPlayerProps {
  src: string;
}

const AudioPlayer = <T extends AudioPlayerProps>({ src, ...props }: T) => {
  const [audioRef, setAudioRef] = React.useState<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(null);

  // Setup the ref for event handling
  React.useEffect(() => {
    const updateTime = () => setCurrentTime(audioRef.currentTime);
    const updateDuration = () => setDuration(audioRef.duration);
    const setInitialState = () => {
      updateTime();
      updateDuration();
    };

    if (audioRef) {
      // We need the oncanplay handler in order to make the time nav work on initial load
      // See: https://coderedirect.com/questions/150982/html-audio-cant-set-currenttime
      audioRef.addEventListener('oncanplay', setInitialState);
      audioRef.addEventListener('canplaythrough', setInitialState);
      audioRef.addEventListener('timeupdate', updateTime);
    }

    return () => {
      if (audioRef) {
        audioRef.removeEventListener('oncanplay', setInitialState);
        audioRef.removeEventListener('canplaythrough', setInitialState);
        audioRef.removeEventListener('timeupdate', updateTime);
      }
    };
  }, [audioRef]);

  React.useEffect(() => {
    if (audioRef) {
      isPlaying ? audioRef.play() : audioRef.pause();
    }
  }, [isPlaying]);

  return (
    <div {...props} key={src}>
      <audio preload="metadata" ref={(r) => setAudioRef(r)}>
        <source src={src}></source>
        The audio element isn't supported on this browser
      </audio>
      <Flex>
        <SkipButton
          motion="back"
          onClick={() => (audioRef.currentTime = audioRef.currentTime - 5)}
        />
        <PlayerButton
          playing={isPlaying}
          onClick={() => setIsPlaying(!isPlaying)}
        />
        <SkipButton
          motion="forward"
          onClick={() => {
            console.log(audioRef.currentTime, audioRef.currentTime + 5);
            audioRef.currentTime = audioRef.currentTime + 5;
          }}
        />
      </Flex>
      {duration && <PlayerBar currentTime={currentTime} duration={duration} />}
    </div>
  );
};

export default AudioPlayer;
