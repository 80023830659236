import { Article } from '../types';
import { encodeQueryString } from './util';

export default class ArticleAPI {
  static createArticle = ({ article }: { article: Article }) => {
    const formData = new FormData();

    formData.set('article', JSON.stringify(article.toJS()));
    formData.set('thumbnailFile', article.thumbnailFile);
    formData.set('audioFile', article.audioFile);

    return fetch(`/api/v1/articles`, {
      method: 'POST',
      body: formData,
    });
  };

  static fetchArticles = ({ options = {} }: { options: Object }) => {
    return fetch(
      `/api/v1/articles${encodeQueryString({
        ...options,
      })}`
    );
  };

  static updateArticle = ({ article }: { article: Article }) => {
    const formData = new FormData();

    formData.set('article', JSON.stringify(article.toJS()));
    formData.set('thumbnailFile', article.thumbnailFile);
    formData.set('audioFile', article.audioFile);

    return fetch(`/api/v1/articles/${article.id}`, {
      method: 'PUT',
      body: formData,
    });
  };
}
