import * as React from 'react';
import { Collapse } from 'react-collapse';

import { getWindowWidth } from '../util/windowWatcher';

import Button from '../button/Button';
import Icon from '../images/Icon';
import Flex from '../box/Flex';

interface NavbarProps {
  resizeAt: number;
  children?: JSX.Element | JSX.Element[];
}

interface NavbarState {
  isNavbarOpen: boolean;
  isMobile: boolean;
}

class ResponsiveNavbar extends React.PureComponent<NavbarProps, NavbarState> {
  static defaultProps: NavbarProps;

  constructor(props) {
    super(props);

    this.state = {
      isNavbarOpen: false,
      isMobile: getWindowWidth() < this.props.resizeAt,
    };

    this.handleResize = this.handleResize.bind(this);

    window.addEventListener('resize', this.handleResize);
  }

  handleResize() {
    const { isMobile } = this.state;

    const currentWidth = getWindowWidth();

    if (!isMobile && currentWidth < this.props.resizeAt) {
      this.setState({ isMobile: true });
    } else if (isMobile && currentWidth > this.props.resizeAt) {
      this.setState({ isMobile: false, isNavbarOpen: false });
    }
  }

  renderMobile() {
    const { isNavbarOpen } = this.state;

    return (
      <nav className="navbar-mobile">
        <Button
          onClick={() => this.setState({ isNavbarOpen: !isNavbarOpen })}
          className="m-y-0 m-x-auto"
        >
          <Icon name={isNavbarOpen ? 'times' : 'bars'} />
        </Button>
        <Collapse isOpened={isNavbarOpen}>
          <Flex direction="column">{this.props.children}</Flex>
        </Collapse>
      </nav>
    );
  }

  render() {
    if (this.state.isMobile) {
      return this.renderMobile();
    }

    return (
      <nav className="navbar">
        <Flex justifyContent="space-around" wrap="wrap">
          {this.props.children}
        </Flex>
      </nav>
    );
  }
}

ResponsiveNavbar.defaultProps = {
  resizeAt: 768,
};

export default ResponsiveNavbar;
