import * as React from 'react';

import Link from '../../../../lib/text/Link';
import DateLabel from '../../../../lib/text/DateLabel';
import Heading from '../../../../lib/text/Heading';
import { Article } from '../../../../types';
import AudioPlayer from '../../../../lib/audio/AudioPlayer';
import { getPathForBlogArticle } from '../../../../constants/BlogConfig';

export interface ArticleHeaderProps {
  article: Article;
  children?: React.ReactNode; // No children indicates no action toolbar
}

export const BlogArticleHeader = ({
  article,
  children,
}: ArticleHeaderProps) => {
  return (
    <div className="relative text-center">
      <Link underline={false} href={getPathForBlogArticle(article)}>
        <Heading type="article">{article.title}</Heading>
      </Link>
      {children}
      <Heading type="subsection-large">
        <DateLabel>{article.date.toString()}</DateLabel>
      </Heading>
      <div className="text-center m-x-auto" style={{ width: 300 }}>
        {article.audioId && <AudioPlayer src={`/files/${article.audioId}`} />}
      </div>
    </div>
  );
};

export const SectionArticleHeader = <P extends ArticleHeaderProps>({
  article,
  children,
}: P) => {
  return (
    <div className="relative text-center">
      <Heading type="article" className="m-bottom-6">
        {article.title}
      </Heading>
      {children}
    </div>
  );
};
