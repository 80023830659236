import { makeRequestActionTypes } from '../lib/requests/RequestTypes';
import {
  makeRequestActions,
  fetchMakerBuilder,
} from '../lib/requests/RequestActions';

import AuthAPI from '../api/AuthAPI';
import ArticleAPI from '../api/ArticleAPI';
import BlogAPI from '../api/BlogAPI';
import ListAPI from '../api/ListAPI';

export const FetchBlogActionTypes = makeRequestActionTypes('BLOGS');
export const FetchBlogActions = makeRequestActions(FetchBlogActionTypes);
export const fetchBlog = fetchMakerBuilder({
  requestActions: FetchBlogActions,
  fetch: BlogAPI.fetchBlog,
});

export const FetchArticlesActionTypes = makeRequestActionTypes('ARTICLES');
export const FetchArticlesActions = makeRequestActions(
  FetchArticlesActionTypes
);
export const fetchArticles = fetchMakerBuilder({
  requestActions: FetchArticlesActions,
  fetch: ArticleAPI.fetchArticles,
});

export const UpdateArticleActionTypes = makeRequestActionTypes(
  'UPDATE_ARTICLE'
);
export const UpdateArticleActions = makeRequestActions(
  UpdateArticleActionTypes
);
export const updateArticle = fetchMakerBuilder({
  requestActions: UpdateArticleActions,
  fetch: ArticleAPI.updateArticle,
});

export const CreateArticleActionTypes = makeRequestActionTypes(
  'CREATE_ARTICLE'
);
export const CreateArticleActions = makeRequestActions(
  CreateArticleActionTypes
);
export const createArticle = fetchMakerBuilder({
  requestActions: CreateArticleActions,
  fetch: ArticleAPI.createArticle,
});

export const FetchAuthActionTypes = makeRequestActionTypes('AUTH');
export const FetchAuthActions = makeRequestActions(FetchAuthActionTypes);
export const fetchAuth = fetchMakerBuilder({
  requestActions: FetchAuthActions,
  fetch: AuthAPI.fetchAuth,
});

export const PostAuthActionTypes = makeRequestActionTypes('POST_AUTH');
export const PostAuthActions = makeRequestActions(PostAuthActionTypes);
export const postAuth = fetchMakerBuilder({
  requestActions: PostAuthActions,
  fetch: AuthAPI.postAuth,
});

export const FetchListActionTypes = makeRequestActionTypes('LIST');
export const FetchListActions = makeRequestActions(FetchListActionTypes);
export const fetchList = fetchMakerBuilder({
  requestActions: FetchListActions,
  fetch: ListAPI.fetchList,
});

export const CreateListItemActionTypes = makeRequestActionTypes(
  'CREATE_LIST_ITEM'
);
export const CreateListItemActions = makeRequestActions(
  CreateListItemActionTypes
);
export const createListItem = fetchMakerBuilder({
  requestActions: CreateListItemActions,
  fetch: ListAPI.createListItem,
});

export const UpdateListItemActionTypes = makeRequestActionTypes(
  'UPDATE_LIST_ITEM'
);
export const UpdateListItemActions = makeRequestActions(
  UpdateListItemActionTypes
);
export const updateListItem = fetchMakerBuilder({
  requestActions: UpdateListItemActions,
  fetch: ListAPI.updateListItem,
});

export const DeleteListItemActionTypes = makeRequestActionTypes(
  'DELETE_LIST_ITEM'
);
export const DeleteListItemActions = makeRequestActions(
  DeleteListItemActionTypes
);
export const deleteListItem = fetchMakerBuilder({
  requestActions: DeleteListItemActions,
  fetch: ListAPI.deleteListItem,
});
