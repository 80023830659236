import { Article, BlogTypes } from '../types';
import assert from '../util/assert';

type BlogConfigType = {
  [k in BlogTypes]: {
    pathname: string;
    allowThumbnails: boolean;
    allowAudioUpload: boolean;
  };
};

const BlogConfig: BlogConfigType = {
  [BlogTypes.BLOG]: {
    pathname: 'blog',
    allowThumbnails: true,
    allowAudioUpload: true,
  },
  [BlogTypes.EMPOWER]: {
    pathname: 'empower-inspire',
    allowThumbnails: false,
    allowAudioUpload: true,
  },
};

export const getPathForBlogArticle = (article: Article) => {
  const blogPathname = BlogConfig[article.blogAlias].pathname;

  assert(blogPathname, 'Expected `blogPathname` to be defined.');

  return `/${blogPathname}/articles/${article.alias}`;
};

export default BlogConfig;
