import * as React from 'react';
import { connect } from 'react-redux';

import CKEditor from '@ckeditor/ckeditor5-react';
import CustomEditor from '../../lib/ckeditor/CustomEditor';

import { updateEditor } from '../../actions/EditorActions';
import { Editor as EditorType } from '../../types';

import { getEditor } from '../../selectors/selectors';
import debounce from '../../util/debounce';

const EDITOR_CONFIG = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'alignment',
      'indent',
      'outdent',
      '|',
      'imageUpload',
      'blockQuote',
      'mediaEmbed',
      'undo',
      'redo',
    ],
  },
  language: 'en',
  image: {
    toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
    upload: {
      types: ['jpg', 'jpeg', 'png', 'gif'],
    },
  },
  simpleUpload: {
    uploadUrl: '/api/v1/files',
  },
};

interface ContentEditorProps {
  articleContent?: string;
  updateEditor: (editor: Partial<EditorType>) => void;
}

const ContentEditor = ({
  articleContent,
  updateEditor,
}: ContentEditorProps) => {
  const debouncedEditorUpdate = React.useRef(debounce(updateEditor));

  const [currentContent, setCurrentContent] = React.useState(articleContent);

  const onUpdateEditor = (newContent) => {
    debouncedEditorUpdate.current({
      hasChanged: newContent !== articleContent,
      rawContent: newContent,
    });
    setCurrentContent(newContent);
  };

  return (
    <CKEditor
      editor={CustomEditor}
      config={EDITOR_CONFIG}
      data={currentContent}
      onChange={(_, editor) => {
        onUpdateEditor(editor.getData());
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  editor: getEditor(state),
});

const mapDispatchToProps = { updateEditor };

export default connect(mapStateToProps, mapDispatchToProps)(ContentEditor);
