import styled from 'styled-components';

const ArticleDisplayContainer = styled.article`
  margin-top: 20px;
  border-bottom: solid 1px black;
  &:last-of-type {
    border-bottom: none;
  }
`;

export default ArticleDisplayContainer;
