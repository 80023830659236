import * as React from 'react';
import { List } from 'immutable';

import { Blog, Article } from '../../types';
import BlogArticlePreview from './article/BlogArticlePreview';
import EmptyArticle from './EmptyArticle';
import Heading from '../../lib/text/Heading';

interface BlogPreviewProps {
  title?: string;
  blog: Blog;
}

const ArticlesContent = ({ articles }: { articles: List<Article> }) => {
  if (articles.size > 0) {
    return (
      <>
        {articles.map((article, i) => (
          <BlogArticlePreview key={i} article={article} />
        ))}
      </>
    );
  }

  return <EmptyArticle />;
};

const BlogPreview = ({ title, blog }: BlogPreviewProps) => {
  return (
    <div className="preview-container">
      {title && (
        <div className="text-center">
          <Heading type="subsection-large" underline={true}>
            {title}
          </Heading>
        </div>
      )}
      <ArticlesContent articles={blog.articles} />
    </div>
  );
};

export default BlogPreview;
