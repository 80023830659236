import * as React from 'react';
import styled from 'styled-components';

import Header from './Header';
import MainNavbar from './Navbar';
import BlogFooter from './Footer';
import ThreePanel from './footer/ThreePanel';
import AlertContainer from '../lib/util/AlertContainer';
import { MediaQueries } from '../lib/constants/ScreenSizes';

const ContentContainer = styled.div`
  margin: 0 15px;

  @media ${MediaQueries.TABLET} {
    margin: 0 30px;
  }

  @media ${MediaQueries.DESKTOP} {
    max-width: 1024px;
    margin: 0 auto;
  }
`;
ContentContainer.displayName = 'ContentContainer';

export type IndexPageConfig = {
  includeThreePanelFooter?: boolean;
  showAboutMeFooter?: boolean;
};

export const wrapIndexPage = <P extends { match }>(
  WrappedElement: React.ComponentType<P>,
  indexPageConfig: IndexPageConfig = {
    includeThreePanelFooter: true,
    showAboutMeFooter: true,
  }
) => {
  const IndexComponent = (props: P) => {
    return (
      <>
        <AlertContainer />
        <section id="body">
          <Header text="POSITIVELY ML" />
          <ContentContainer>
            <MainNavbar />
            <WrappedElement {...props} />
            {indexPageConfig.includeThreePanelFooter && (
              <ThreePanel showAboutMe={indexPageConfig.showAboutMeFooter} />
            )}
            <BlogFooter />
          </ContentContainer>
        </section>
      </>
    );
  };

  return IndexComponent;
};
