import { Record } from 'immutable';

import * as React from 'react';
import { connect } from 'react-redux';

import { RequestStatus } from '../../lib/requests/RequestTypes';
import LoaderOverlay from './LoaderOverlay';

interface LoaderDepProps {
  getStatus: (...args: any[]) => RequestStatus;
  fetchData: (...args: any[]) => void;
}
export class LoaderDep extends Record<LoaderDepProps>({
  getStatus: () => RequestStatus.UNINITIALIZED,
  fetchData: () => {},
}) {
  constructor(getStatus, fetchData) {
    super({ fetchData, getStatus });
  }
}

type DynamicLoadDeps<P> = (ownProps: P) => LoaderDep[];

interface MakeLoaderHOCProps<P> {
  loadDeps: DynamicLoadDeps<P> | LoaderDep[];
  ErrorComponent: React.ElementType;
  shouldRefetch?: (
    props: Omit<P, keyof React.PropsWithChildren<LoaderWrapperProps>>,
    prevProps: Omit<P, keyof React.PropsWithChildren<LoaderWrapperProps>>
  ) => boolean;
  useLoadingState?: boolean;
}

interface LoaderWrapperProps {
  fetchers: Array<() => void>;
  statusArray: Array<RequestStatus>;
}

export const makeLoaderHOC = <P,>({
  loadDeps,
  ErrorComponent,
  shouldRefetch = () => false,
  useLoadingState = true,
}: MakeLoaderHOCProps<P>) => {
  type ExtendedWrapperProps = React.PropsWithChildren<P & LoaderWrapperProps>;

  const hasDynamicLoadDeps = typeof loadDeps === 'function';

  let isFirstMount = true;

  const LoaderWrapper = ({
    fetchers,
    statusArray,
    children,
    ...extraProps
  }: ExtendedWrapperProps) => {
    const prevProps = React.useRef(extraProps);

    React.useEffect(() => {
      if (isFirstMount || shouldRefetch(extraProps, prevProps.current)) {
        fetchers.forEach((fetch) => fetch());
      }

      isFirstMount = false;
      prevProps.current = extraProps;
    });

    const hasError = statusArray.some(
      (status) => status === RequestStatus.FAILED
    );
    const hasLoaded = statusArray.every(
      (status) => status === RequestStatus.SUCCEEDED
    );

    return useLoadingState ? (
      <LoaderOverlay
        FallbackComponent={ErrorComponent}
        hasError={hasError}
        hasLoaded={hasLoaded}
      >
        {children}
      </LoaderOverlay>
    ) : (
      children
    );
  };

  const mapStateToProps = (state, ownProps: ExtendedWrapperProps) => ({
    statusArray: hasDynamicLoadDeps
      ? (loadDeps as DynamicLoadDeps<ExtendedWrapperProps>)(ownProps).map((_) =>
          _.getStatus(state)
        )
      : (loadDeps as LoaderDep[]).map((_) => _.getStatus(state)),
  });

  const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchers: hasDynamicLoadDeps
      ? (loadDeps as DynamicLoadDeps<ExtendedWrapperProps>)(
          ownProps
        ).map((_) => (...args) => dispatch(_.fetchData(...args)))
      : (loadDeps as LoaderDep[]).map((_) => (...args) =>
          dispatch(_.fetchData(...args))
        ),
  });

  const ConnectedLoader = connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoaderWrapper);

  return (WrappedComponent) => {
    return (props) => (
      <ConnectedLoader {...props}>
        <WrappedComponent {...props} />
      </ConnectedLoader>
    );
  };
};
