import * as React from 'react';

import classNames from 'classnames';

export interface ListItemProps {
  type?: string;
  onClick?: () => void;
}

const ListItem = ({
  onClick,
  children,
  type = 'arrow',
}: React.PropsWithChildren<ListItemProps>) => {
  const className = classNames({
    'arrow-item': type === 'arrow',
  });

  return (
    <li className={className} onClick={onClick}>
      {children}
    </li>
  );
};

export default ListItem;
