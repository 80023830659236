import React from 'react';
import Paragraph from './Paragraph';

interface BlockQuoteProps {
  content: React.ReactNode;
  author?: React.ReactNode;
}

class BlockQuote extends React.Component<BlockQuoteProps, {}> {
  render() {
    const { content, author } = this.props;
    return (
      <blockquote className="blockquote text-center">
        <Paragraph className="quote-content m-bottom-4">{content}</Paragraph>
        {author && (
          <Paragraph className="quote-author" align="right" type="soft">
            - {author}
          </Paragraph>
        )}
      </blockquote>
    );
  }
}

export default BlockQuote;
