import * as React from 'react';
import classNames from 'classnames';

interface SectionProps {
  width: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

class Section extends React.PureComponent<SectionProps, {}> {
  static defaultProps: SectionProps;

  computeClassName() {
    const { className } = this.props;
    const marginWidth = this.computeMarginWidth();

    return classNames({
      [`m-top-${marginWidth} m-bottom-${marginWidth}`]: true,
      [className]: Boolean(className)
    });
  }

  computeMarginWidth() {
    const { width } = this.props;

    switch (width) {
      case 'small':
        return 2;
      case 'medium':
        return 4;
      case 'large':
        return 8;
      case 'xl':
        return 10;
    }
  }

  render() {
    const { children } = this.props;

    return <div className={this.computeClassName()}>{children}</div>;
  }
}

Section.defaultProps = {
  width: 'small'
};

export default Section;
