import * as React from 'react';
import classNames from 'classnames';
import {
  AbstractInputComponentProps,
  AbstractSyntheticEvent,
} from '../../types';

interface TextInputProps
  extends AbstractInputComponentProps<string, AbstractSyntheticEvent<string>> {
  inputType?: string;
  type?: 'underline' | 'bordered';
  size?: number;
  initialValue?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (evt: AbstractSyntheticEvent<string>) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  size,
  value,
  initialValue,
  placeholder,
  className,
  onChange,
  type = 'bordered',
  inputType = 'text',
}: TextInputProps) => {
  const [controlledValue, setControlledValue] = React.useState(initialValue);
  const isLocallyControlled =
    initialValue !== undefined && initialValue !== null;
  const isGloballyControlled = value !== undefined && value !== null;

  const handleInputChange = (evt) => {
    if (isLocallyControlled) {
      setControlledValue(evt.target.value);
    }
    if (onChange) {
      onChange(evt);
    }
  };

  const classes = classNames({
    'input-box': type === 'bordered',
    'input-underline': type === 'underline',
    [className]: Boolean(className),
  });

  const styles = size ? { fontSize: size } : {};
  const valueProps = {
    value: isGloballyControlled
      ? value
      : isLocallyControlled
      ? controlledValue
      : null,
  };

  return (
    <input
      type={inputType}
      {...valueProps}
      style={styles}
      className={classes}
      placeholder={placeholder}
      onChange={handleInputChange}
    />
  );
};

export default TextInput;
