import * as React from 'react';
import { AlertType } from '../../types';
import Paragraph from '../text/Paragraph';
import Button from '../button/Button';
import Icon from '../images/Icon';
import Flex from '../box/Flex';
import {
  ERROR_RED,
  WARNING_YELLOW,
  SUCCESS_GREEN,
  INFO_GREY,
} from '../constants/colors';

interface AlertToastItemProps {
  type: AlertType;
  message: string;
  onClose: () => void;
}

const AlertTypeProps = {
  [AlertType.ERROR]: {
    icon: 'exclamation-triangle',
    color: ERROR_RED,
  },
  [AlertType.WARNING]: {
    icon: 'exclamation-triangle',
    color: WARNING_YELLOW,
  },
  [AlertType.INFO]: {
    icon: 'exclamation-circle',
    color: INFO_GREY,
  },
  [AlertType.SUCCESS]: {
    icon: 'check-circle',
    color: SUCCESS_GREEN,
  },
};

const AlertToastItem = ({ type, message, onClose }: AlertToastItemProps) => {
  const { icon, color } = React.useMemo(() => AlertTypeProps[type], [type]);

  return (
    <div
      className="relative m-bottom-2 p-x-6 p-y-4"
      style={{
        borderRadius: 2,
        backgroundColor: 'white',
        border: '1px solid black',
        boxShadow: '0 2px 5px #888888',
      }}
    >
      <Flex justifyContent="space-between">
        <Flex
          direction="column"
          justifyContent="center"
          className="width-initial m-right-4"
        >
          <Icon name={icon} style={{ color }} />
        </Flex>
        <Paragraph>{message}</Paragraph>
        <Button purpose="meta" className="m-left-2" onClick={onClose}>
          <Icon name="times" />
        </Button>
      </Flex>
    </div>
  );
};

export default AlertToastItem;
