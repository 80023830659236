import * as React from 'react';

import Strings from '../../Strings';
import Paragraph from '../../lib/text/Paragraph';
import FormattedHTMLString from '../../lib/text/FormattedHTMLString';
import Heading from '../../lib/text/Heading';
import useStyles from '../../lib/util/useStyles';
import ScreenSizes from '../../lib/constants/ScreenSizes';
import { makeUUID } from '../../util/strings';
import WebpImage from '../../lib/images/WebpImage';

interface CollegeConsultingContainerProps {}

const CollegeConsultingContainer = (props: CollegeConsultingContainerProps) => {
  const imageStyles = useStyles({
    [ScreenSizes.MOBILE_UP]: {
      width: '90%',
      display: 'block',
      margin: '0 auto',
    },
    [ScreenSizes.TABLET_UP]: { width: '60%' },
  });

  return (
    <section id="consulting">
      <Heading type="article" className="text-center m-y-6">
        {Strings.consulting.heading}
      </Heading>
      <WebpImage
        src="/static/img/CollegePhoto.webp"
        fallback="/static/img/CollegePhoto.jpg"
        alt="Book of best colleges"
        style={imageStyles}
      />
      <Paragraph className="m-y-6">{Strings.consulting.content[0]}</Paragraph>
      <Paragraph className="m-y-6 width-80 m-y-0 m-x-auto">
        <ul>
          {Strings.consulting.content.slice(1, 7).map((paragraph) => (
            <FormattedHTMLString key={makeUUID(paragraph)}>
              {paragraph}
            </FormattedHTMLString>
          ))}
        </ul>
      </Paragraph>
      {Strings.consulting.content.slice(7).map((paragraph) => (
        <Paragraph key={makeUUID(paragraph)} className="m-y-6">
          <FormattedHTMLString>{paragraph}</FormattedHTMLString>
        </Paragraph>
      ))}
    </section>
  );
};

export default CollegeConsultingContainer;
