import draftToHtml from 'draftjs-to-html';

import AttributeReplacer from './AttributeReplacer';
import { ELEMENT_REPLACEMENTS } from './constants';

// @unused; old stuff used for annoying draft.js parsing
const replaceTags = (html, replacementDefs) => {
  return Object.keys(replacementDefs).reduce((replacedHTML, tag) => {
    const {
      tagName,
      defaultAttributes = {},
      attributeMappings = {},
      styleMappings = {},
      isSelfClosing = false,
    } = replacementDefs[tag];

    const res = new AttributeReplacer(replacedHTML, tag, tagName, isSelfClosing)
      .replaceHTML(defaultAttributes, attributeMappings, styleMappings)
      .replaceClosingTags()
      .getHTML();

    return res;
  }, html);
};

// @unused; same as above
export const transformToHTML = (rawContent) => {
  return replaceTags(draftToHtml(rawContent), ELEMENT_REPLACEMENTS);
};

export const fixSelfClosingTags = (html, tagNames = []) => {
  return tagNames.reduce((fixedHtml, tagName) => {
    return fixedHtml.replace(
      new RegExp(`<${tagName} (.*?)>`, 'g'),
      (match) => match.slice(0, -1) + '/>'
    );
  }, html);
};

export const removeImageTags = (html) => {
  return html.replace(/<figure.*<\/figure>/g, '');
};
