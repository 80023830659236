import React from 'react';
import Moment from 'react-moment';

interface DateLabelProps {
  format: string;
  children?: string;
}

class DateLabel extends React.PureComponent<DateLabelProps, {}> {
  static defaultProps: DateLabelProps;

  render() {
    return <Moment format={this.props.format}>{this.props.children}</Moment>;
  }
}

DateLabel.defaultProps = {
  format: 'MMM D, YYYY',
};

export default DateLabel;
