import * as React from 'react';

const List = ({
  className,
  children
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return <ul className={className}>{children}</ul>;
};

export default List;
