import { Map, List } from 'immutable';

import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import requestReducer from '../lib/requests/RequestReducer';

import {
  FetchBlogActionTypes,
  FetchArticlesActionTypes,
  FetchAuthActionTypes,
  PostAuthActionTypes,
  UpdateArticleActionTypes,
  CreateArticleActionTypes,
  FetchListActionTypes,
} from '../actions/APIActions';
import { EditorActionTypes } from '../actions/EditorActions';
import {
  Blog,
  Auth,
  Article,
  Editor,
  AlertType,
  AlertItem,
  EditableList,
  EditableListItem,
} from '../types';
import { mapArticle } from '../util/mappers';
import assert from '../util/assert';
import { AlertActionTypes } from '../actions/AlertActions';
import { makeUUID } from '../util/strings';

export const initialStoreState = Map({
  alerts: List(),
  auth: new Auth(),
  blog: new Blog(),
  editor: new Editor(),
  lists: Map(),
});

const mainReducer = handleActions(
  {
    [FetchAuthActionTypes.RECEIVE](state, { payload }) {
      const { data } = payload;
      return state.set('auth', new Auth({ hasWriteAccess: data.isAdmin }));
    },
    [PostAuthActionTypes.RECEIVE](state, { payload }) {
      const { data } = payload;
      return state.set(
        'auth',
        new Auth({
          hasWriteAccess: data.authenticated,
          hasError: !data.authenticated && data.attempted,
        })
      );
    },
    [combineActions(
      PostAuthActionTypes.FAILURE,
      CreateArticleActionTypes.FAILURE
    )](state, { payload }) {
      const errorResponse = payload.error && payload.error.response;

      assert(
        Boolean(errorResponse),
        'Request failed and API returned no error message'
      );

      if (errorResponse.readable) {
        return state.set(
          'alerts',
          state.get('alerts').push(
            new AlertItem({
              id: makeUUID(errorResponse.readable),
              message: errorResponse.readable,
              alertType: AlertType.ERROR,
            })
          )
        );
      }
      return state;
    },
    [FetchBlogActionTypes.RECEIVE](state, { payload }) {
      const { data } = payload;

      const articles = List(data.articles.map(mapArticle)).sortBy(
        (a: Article) => -a.date
      );
      return state.set('blog', new Blog({ ...data, articles }));
    },
    [UpdateArticleActionTypes.RECEIVE](state, { payload }) {
      const { data } = payload;
      const articleIndex = state
        .getIn(['blog', 'articles'])
        .findIndex((a) => a.id === data.id);

      if (articleIndex < 0) {
        return state;
      }

      return state.setIn(['blog', 'articles', articleIndex], mapArticle(data));
    },
    [CreateArticleActionTypes.RECEIVE](state, { payload }) {
      const { data, fetchArgs } = payload;

      const updatedState = state.setIn(
        ['blog', 'articles'],
        state.getIn(['blog', 'articles']).push(mapArticle(data))
      );

      if (fetchArgs.isTemplate) {
        return updatedState;
      }

      return updatedState.set(
        'alerts',
        updatedState.get('alerts').push(
          new AlertItem({
            id: makeUUID(data.id),
            message: 'Success! Your article has been created. Loading...',
            alertType: AlertType.SUCCESS,
          })
        )
      );
    },
    [FetchArticlesActionTypes.RECEIVE](state, { payload }) {
      return state.withMutations((mutable) => {
        payload.data.forEach((article) =>
          mutable.setIn(
            ['blog', 'articles'],
            mutable.getIn(['blog', 'articles']).push(mapArticle(article))
          )
        );
        return mutable;
      });
    },
    [EditorActionTypes.UPDATE_EDITOR](state, { payload }) {
      return state.set('editor', state.get('editor').merge(payload));
    },
    [AlertActionTypes.CLOSE_ALERT](state, { payload }) {
      assert(
        Boolean(payload && payload.index !== undefined),
        'Member `index` of the payload for CLOSE_ALERT action type must be present'
      );

      return state.set('alerts', state.get('alerts').splice(payload.index, 1));
    },
    [FetchListActionTypes.RECEIVE](state, { payload }) {
      const { data } = payload;

      return state.setIn(
        ['lists', data.name],
        new EditableList({
          ...data,
          items: List<EditableListItem>(
            data.items.map((_) => new EditableListItem(_))
          ).sortBy((_) => _.id),
        })
      );
    },
  },
  initialStoreState
);

export default combineReducers({
  store: mainReducer,
  requestStatuses: requestReducer,
});
