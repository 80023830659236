import * as React from 'react';
import { findDOMNode } from 'react-dom';

import Heading from './Heading';
import Paragraph from './Paragraph';

const defaultPopoverProps = { maxWidth: 350 };

interface PopoverProps
  extends React.PropsWithChildren<typeof defaultPopoverProps> {
  title?: React.ReactNode;
  text: React.ReactNode;
  use: 'tooltip' | 'popover';
  target: HTMLElement;
}

const computePopoverPosition = (
  targetRect: DOMRect,
  popoverRect: DOMRect
): { top: number; left: number } => {
  let computedTop = targetRect.y - targetRect.height - 4; // subtract 4 px for extra padding in between popover and content above
  let computedLeft =
    (2 * targetRect.x + targetRect.width - popoverRect.width) / 2;

  // If the bottom of the popover intersects with the target content, manually set it to have 4 pixels of space in between
  if (popoverRect.bottom > targetRect.top) {
    computedTop = targetRect.top - popoverRect.height - 4;
  }

  return { top: computedTop, left: computedLeft };
};

const Popover = ({ title, text, use, maxWidth, target }: PopoverProps) => {
  const popoverRef = React.useRef(null);
  const [popoverRect, setPopoverRect] = React.useState(new DOMRect());

  const targetNode = findDOMNode(target) as HTMLElement;
  const targetRect = targetNode.getBoundingClientRect();

  const positionStyles = computePopoverPosition(targetRect, popoverRect);

  const fontStyles =
    use === 'tooltip' ? { color: 'white', backgroundColor: 'black' } : {};

  const displayStyles = !popoverRect.width ? { opacity: 0 } : {};

  React.useEffect(() => {
    setPopoverRect(popoverRef.current.getBoundingClientRect());
  }, []);

  return (
    <div
      className="popover-root"
      style={{
        ...positionStyles,
        ...fontStyles,
        ...displayStyles,
        maxWidth,
      }}
      ref={popoverRef}
    >
      {title && <Heading type="subsection-small">{title}</Heading>}
      <Paragraph size="small" type="info" className="m-all-0 p-x-3 p-y-1">
        {text}
      </Paragraph>
    </div>
  );
};

Popover.defaultProps = defaultPopoverProps;

export default Popover;
