import * as React from 'react';
import styled from 'styled-components';

import DraftContent from '../../shared/DraftContent';
import ArticlePreviewContentWrapper from '../../wrappers/ArticlePreviewContentWrapper';
import { removeImageTags } from '../../../util/parsing/parser';
import Image from '../../../lib/images/Image';
import { Article } from '../../../types';
import { MediaQueries } from '../../../lib/constants/ScreenSizes';

const ScreenSensitiveImage = styled(Image)`
  width: 200px;

  @media ${MediaQueries.TABLET} {
    width: 300px;
  }

  @media ${MediaQueries.SMALL_DESKTOP} {
    width: 100%;
  }
`;
ScreenSensitiveImage.displayName = 'ScreenSensitiveImage';

interface ArticlePreviewContentProps {
  article: Article;
}

const ArticlePreviewContent = ({ article }: ArticlePreviewContentProps) => {
  const imagelessContent = React.useMemo(
    () => removeImageTags(article.content),
    [article.content]
  );

  return (
    <div className="row">
      {article.thumbnailId && (
        <div className="text-center col-sm-12 col-md-4 p-x-0 m-bottom-4">
          <ScreenSensitiveImage src={`/files/${article.thumbnailId}`} />
        </div>
      )}
      <div className={`col-sm-12 col-md-${article.thumbnailId ? '8' : '12'}`}>
        <ArticlePreviewContentWrapper>
          <DraftContent html={imagelessContent} />
        </ArticlePreviewContentWrapper>
      </div>
    </div>
  );
};

export default React.memo(
  ArticlePreviewContent,
  ({ article: prevArticle }, { article }) =>
    prevArticle.content === article.content &&
    prevArticle.thumbnailId === article.thumbnailId
);
