import { createAction } from 'redux-actions';

export enum AlertActionTypes {
  CLOSE_ALERT = 'CLOSE_ALERT',
}

export const closeAlert = createAction(
  AlertActionTypes.CLOSE_ALERT,
  ({ index }) => ({ index })
);
