import * as React from 'react';

import { Article } from '../../../types';

import Link from '../../../lib/text/Link';
import { BlogArticleHeader } from './header/ArticleHeaders';
import ArticlePreviewContent from './ArticlePreviewContent';
import ArticleDisplayContainer from '../../wrappers/ArticleDisplayContainer';
import BlogConfig, {
  getPathForBlogArticle,
} from '../../../constants/BlogConfig';
import assert from '../../../util/assert';

interface BlogArticlePreviewProps {
  article: Article;
}

const BlogArticlePreview = ({ article }: BlogArticlePreviewProps) => {
  return (
    <ArticleDisplayContainer>
      <BlogArticleHeader article={article} />
      <ArticlePreviewContent article={article} />
      <div className="text-center m-bottom-4">
        <Link
          className="text-center"
          type="animate"
          href={getPathForBlogArticle(article)}
          underline={false}
        >
          READ MORE
        </Link>
      </div>
    </ArticleDisplayContainer>
  );
};

export default BlogArticlePreview;
