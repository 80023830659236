import * as React from 'react';
import ReactDOM, { findDOMNode } from 'react-dom';
import Popover from './Popover';

interface TooltipProps extends React.PropsWithChildren<{}> {
  title?: string;
  text: string;
  disabled?: boolean;
}

interface ChildrenRefWrapperProps extends React.PropsWithChildren<{}> {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const ChildrenRefWrapper = React.forwardRef<
  HTMLDivElement,
  ChildrenRefWrapperProps
>(
  (
    { children, onMouseEnter, onMouseLeave }: ChildrenRefWrapperProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div
        id="ref-wrapper"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    );
  }
);

const Tooltip = ({ title, text, disabled, children }: TooltipProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const timeoutRef = React.useRef(null);
  const childRef = React.useRef<HTMLDivElement>(null);

  const openPopover = React.useCallback(() => {
    if (!disabled && !isOpen) {
      timeoutRef.current = setTimeout(() => {
        setIsOpen(true);
      }, 300);
    }
  }, [setIsOpen, disabled, isOpen]);

  const closePopover = React.useCallback(() => {
    // Clear the timeout if the user leaves the bounding rectangle before the popover shows
    clearTimeout(timeoutRef.current);
    setIsOpen(false);
  }, [setIsOpen]);

  const onlyChild: React.ReactNode = React.Children.only(children);

  const evtHandlerProps = {
    onMouseEnter: openPopover,
    onMouseLeave: closePopover,
  };

  const popoverContent = ReactDOM.createPortal(
    <Popover
      title={title}
      text={text}
      use="tooltip"
      target={childRef.current}
    />,
    document.body
  );

  return (
    <>
      <ChildrenRefWrapper ref={childRef} {...evtHandlerProps}>
        {onlyChild}
      </ChildrenRefWrapper>
      {isOpen && !disabled && popoverContent}
    </>
  );
};

export default Tooltip;
