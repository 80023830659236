import { createAction } from 'redux-actions';

import { RequestStatus } from './RequestTypes';

export const makeRequestActions = (
  { REQUEST, RECEIVE, FAILURE, RESET },
  { createMetaCreator = (id: any) => ({}) } = {}
) => {
  return {
    request: createAction(
      REQUEST,
      (id) => id,
      (id) => {
        return {
          requestStatuses: {
            [REQUEST]: RequestStatus.PENDING,
          },
          ...createMetaCreator(id),
        };
      }
    ),
    receive: createAction(
      RECEIVE,
      (id) => id,
      () => ({
        requestStatuses: {
          [REQUEST]: RequestStatus.SUCCEEDED,
        },
      })
    ),
    failure: createAction(
      FAILURE,
      (id) => id,
      () => ({
        requestStatuses: {
          [REQUEST]: RequestStatus.FAILED,
        },
      })
    ),
    reset: createAction(
      RESET,
      (id) => id,
      () => ({
        requestStatuses: {
          [REQUEST]: RequestStatus.UNINITIALIZED,
        },
      })
    ),
  };
};

export const fetchMakerBuilder = ({ requestActions, fetch }) => (
  argObj,
  payload = {}
) => {
  return (dispatch) => {
    dispatch(requestActions.request({ payload }));
    fetch(argObj)
      .then(async (resp) => {
        let ret;
        if (!resp.ok) {
          let throwable;
          try {
            ret = await resp.json();
          } catch (err) {
            throwable = { ...err, status: resp.status };
            throw throwable;
          }
          throwable = { status: resp.status, response: ret };
          throw throwable;
        }

        return resp.json().catch(() => ({}));
      })
      .then((resp) => {
        dispatch(
          requestActions.receive({
            data: resp,
            fetchArgs: argObj,
            requestPayload: payload,
          })
        );
      })
      .catch((err) => {
        dispatch(
          requestActions.failure({
            error: err,
            fetchArgs: argObj,
            requestPayload: payload,
          })
        );
      });
  };
};
