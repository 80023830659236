import React, { Component, CSSProperties } from 'react';
import classNames from 'classnames';

interface ButtonProps {
  type: 'button' | 'submit';
  purpose: 'action' | 'meta';
  onClick?: (any) => void;
  style?: CSSProperties;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

class Button extends Component<ButtonProps, {}> {
  static defaultProps: ButtonProps;

  getClassNames() {
    const { purpose, className, disabled } = this.props;
    return classNames({
      'disabled-button': Boolean(disabled),
      'action-button': purpose === 'action',
      'meta-button': purpose === 'meta',
      [className]: Boolean(className),
    });
  }

  render() {
    const { type, children, style, onClick, disabled } = this.props;

    return (
      <button
        style={style}
        className={this.getClassNames()}
        type={type}
        onClick={onClick}
        {...(disabled && { disabled: Boolean(disabled) })}
      >
        {children}
      </button>
    );
  }
}

Button.defaultProps = {
  purpose: 'action',
  type: 'button',
};

export default Button;
