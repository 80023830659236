import { encodeQueryString } from './util';

export default class BlogAPI {
  static fetchBlog = (
    blogAlias: string, // TODO: Could be more specific here
    { options = {} }: { options: Object } = { options: {} }
  ) => {
    return fetch(`/api/v1/blog/${blogAlias}${encodeQueryString(options)}`);
  };
}
