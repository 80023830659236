import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const FullWidthImage = styled.img`
  width: 100%;
`;

interface Props {
  src: string;
  alt?: string;
  style?: React.CSSProperties;
  className?: string;
}

class Image extends Component<Props, {}> {
  render() {
    const { src, alt, className, style } = this.props;

    const mergedClasses = classNames({
      [className]: Boolean(className),
    });

    return (
      <FullWidthImage
        className={mergedClasses}
        src={src}
        alt={alt}
        style={style}
      />
    );
  }
}

export default Image;
