export const ROOT = '/';
export const ADMIN = '/admin';
export const ABOUT = '/about';
export const BLOG = '/blog';
export const CONTENT_CREATION = '/content-creation';
export const PROJECT_MANAGEMENT = '/project-management';
export const BLOG_CREATE = `${BLOG}/create`;
export const BLOG_ARTICLE = `${BLOG}/articles/:articleAlias`;

export const EMPOWER_INSPIRE = '/empower-inspire';
export const EMPOWER_INSPIRE_CREATE = `${EMPOWER_INSPIRE}/create`;
export const EMPOWER_INSPIRE_ARTICLE = `${EMPOWER_INSPIRE}/articles/:articleAlias`;

// Consulting maps to "Coaching" in text
const CONSULTING = '/coaching';
export const COLLEGE_CONSULTING = `${CONSULTING}/college`;
export const CAREER_CONSULTING = `${CONSULTING}/career`;
