import * as React from 'react';

import styled from 'styled-components';
import {
  AbstractInputComponentProps,
  AbstractSyntheticEvent,
} from '../../types';
import { makeSyntheticEvent } from '../../util/typing';
import { ListItemValue } from '../list/LinkListItem';
import Paragraph from '../text/Paragraph';
import Icon from '../images/Icon';
import TextInput from './TextInput';

const LinkInputContainer = styled.div`
  z-index: 999;
  border: solid 1px black;
  border-radius: 3px;
  padding: 0 12px;
  margin-bottom: 8px;
`;

interface LinkInputProps
  extends AbstractInputComponentProps<
    ListItemValue,
    AbstractSyntheticEvent<ListItemValue>
  > {
  value: ListItemValue;
  onChange: (evt: AbstractSyntheticEvent<ListItemValue>) => void;
}

const LinkInput = ({ value, onChange }: LinkInputProps) => {
  return (
    <LinkInputContainer>
      <Paragraph type="info">Label</Paragraph>
      <TextInput
        initialValue={value.label}
        onChange={({ target: { value: newValue } }) =>
          onChange(
            makeSyntheticEvent({
              href: value.href,
              label: newValue,
            })
          )
        }
      />
      <Paragraph type="info">
        Link URL
        <Icon name="link" className="m-left-1" style={{ fontSize: '0.5rem' }} />
      </Paragraph>
      <TextInput
        initialValue={value.href}
        onChange={({ target: { value: newValue } }) =>
          onChange(
            makeSyntheticEvent({
              href: newValue,
              label: value.label,
            })
          )
        }
      />
    </LinkInputContainer>
  );
};

export default LinkInput;
