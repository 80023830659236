import * as React from 'react';
import { connect } from 'react-redux';

import { getAuth } from '../../selectors/selectors';

export const withAuth = (WrappedComponent) => {
  const AuthWrapper = ({ auth, ...props }) => {
    return <WrappedComponent hasWriteAccess={auth.hasWriteAccess} {...props} />;
  };

  const mapStateToProps = (state) => ({
    auth: getAuth(state),
  });

  return connect(mapStateToProps)(AuthWrapper);
};
