import * as React from 'react';

import Button from '../button/Button';
import Icon from '../images/Icon';

interface SkipButtonProps {
  motion: 'back' | 'forward';
  onClick: () => void;
}

const SkipButton = ({ motion, onClick }: SkipButtonProps) => {
  return (
    <Button
      onClick={onClick}
      purpose="meta"
      style={{ fontSize: 16, margin: 'auto' }}
    >
      <Icon name={motion === 'back' ? 'undo' : 'redo'} />
    </Button>
  );
};

export default SkipButton;
