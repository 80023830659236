export enum ScreenSizes {
  DESKTOP_UP = 992, // min-width: 992px
  SMALL_DESKTOP_UP = 768, // min-width: 768px
  TABLET_UP = 481, // min-width: 481px
  MOBILE_UP = 0, // min-width: 0px
}

export enum MediaQueries {
  DESKTOP = `(min-width: 992px)`,
  SMALL_DESKTOP = `(min-width: 768px)`,
  TABLET = `(min-width: 481px)`,
}

export default ScreenSizes;
