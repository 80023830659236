import * as React from 'react';

import Flex from '../box/Flex';

interface FooterProps {
  children?: JSX.Element | JSX.Element[];
}

class Footer extends React.Component<FooterProps, {}> {
  render() {
    const { children } = this.props;

    return (
      <footer>
        <Flex justifyContent="center">{children}</Flex>
      </footer>
    );
  }
}

export default Footer;
