import React from 'react';
import classNames from 'classnames';

interface ParagraphProps {
  size: 'small' | 'medium' | 'large';
  align?: string;
  overflowWrap?: boolean;
  className?: string;
  type?: 'soft' | 'error' | 'info';
  children?: React.ReactNode;
}

class Paragraph extends React.PureComponent<ParagraphProps, {}> {
  static defaultProps: ParagraphProps;

  makeClassName() {
    const { overflowWrap, size, className, type, align } = this.props;

    return classNames({
      paragraph: true,
      'overflow-wrap': Boolean(overflowWrap),
      'paragraph-small': size === 'small',
      'paragraph-medium': size === 'medium',
      'paragraph-large': size === 'large',
      'soft-text': type === 'soft',
      'error-text': type === 'error',
      'info-text': type === 'info',
      'text-center': align === 'center',
      'text-right': align === 'right',
      'text-justify': align === 'justify',
      [className]: Boolean(className),
    });
  }

  render() {
    return <p className={this.makeClassName()}>{this.props.children}</p>;
  }
}

Paragraph.defaultProps = {
  size: 'medium',
};

export default Paragraph;
