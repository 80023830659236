import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  library,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faTimes,
  faEdit,
  faSave,
  faBan,
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faPencilAlt,
  faTrash,
  faImage,
  faCloudUploadAlt,
  faLink,
  faVolumeUp,
  faPauseCircle,
  faPlayCircle,
  faUndo,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBars,
  faTimes,
  faEdit,
  faSave,
  faBan,
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faPencilAlt,
  faTrash,
  faImage,
  faCloudUploadAlt,
  faLink,
  faVolumeUp,
  faPauseCircle,
  faPlayCircle,
  faUndo,
  faRedo
);

interface Props {
  name: string;
  type?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

class Icon extends Component<Props> {
  render() {
    const { name, type, style, className, children } = this.props;

    const iconPrefix = (() => {
      switch (type) {
        case 'brand':
          return 'fab';
        case 'light':
          return 'fal';
        case 'solid':
        default:
          return 'fas';
      }
    })();

    return (
      <span className={`icon-container ${className}`} style={style}>
        <FontAwesomeIcon icon={[iconPrefix, name] as [IconPrefix, IconName]} />
        {children}
      </span>
    );
  }
}

export default Icon;
