import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Button from '../../lib/button/Button';
import Icon from '../../lib/images/Icon';

interface NewArticleButtonProps {}

const NewArticleButton = <
  WithRouterProps extends RouteComponentProps & NewArticleButtonProps
>({
  history,
}: WithRouterProps) => {
  return (
    <Button
      className="m-y-0 m-x-auto"
      onClick={() => history.push(`${location.pathname}/create`)}
    >
      <Icon name="edit" className="m-right-2" />
      <span>New article</span>
    </Button>
  );
};

export default withRouter(NewArticleButton);
