import * as React from 'react';

import { BarLoader } from 'react-css-loaders';

const LoaderOverlay = ({
  FallbackComponent,
  hasError,
  hasLoaded,
  children = null,
}) => {
  if (hasError) {
    return <FallbackComponent />;
  }

  if (hasLoaded) {
    return children;
  }

  return (
    <div style={{ height: 125 }}>
      <BarLoader size={6} />
    </div>
  );
};

export default LoaderOverlay;
