import { createAction } from 'redux-actions';
import { Editor } from '../types';

export enum EditorActionTypes {
  UPDATE_IS_EDITING = 'UPDATE_IS_EDITING',
  UPDATE_HAS_CHANGED = 'UPDATE_HAS_CHANGED',
  UPDATE_EDITOR = 'UPDATE_EDITOR',
}

export const updateEditor = createAction(
  EditorActionTypes.UPDATE_EDITOR,
  (editor: Editor | Partial<{ [k in keyof Editor]: Editor[k] }>) => editor
);

// @unused
export const updateIsEditing = createAction(
  EditorActionTypes.UPDATE_IS_EDITING,
  ({ isEditing }) => ({ isEditing })
);

// @unused
export const updateHasChanged = createAction(
  EditorActionTypes.UPDATE_HAS_CHANGED,
  ({ hasChanged }) => ({ hasChanged })
);
