import * as React from 'react';

interface NavDropdownProps {
  children?: React.ReactNode;
}

class NavDropdown extends React.PureComponent<NavDropdownProps, {}> {
  render() {
    return <div className="dropdown-content">{this.props.children}</div>;
  }
}

export default NavDropdown;
