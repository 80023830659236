import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import BlogPreview from '../blog/BlogPreview';
import NewArticleButton from '../blog/NewArticleButton';
import { Auth, Blog, BlogTypes, SectionContainerTypes } from '../../types';
import { getAuth, getBlog } from '../../selectors/selectors';
import { makeBlogLoaderHOC } from './../hocs/LoaderHOCs';
import BlockQuote from '../../lib/text/BlockQuote';
import WebpImage from '../../lib/images/WebpImage';
import Strings from '../../Strings';
import Heading from '../../lib/text/Heading';
import Paragraph from '../../lib/text/Paragraph';
import FormattedHTMLString from '../../lib/text/FormattedHTMLString';

interface EmpowerInspireContainerProps {
  blog: Blog;
  auth: Auth;
}

const EmpowerInspireContainer = ({
  auth,
  blog,
}: EmpowerInspireContainerProps) => (
  <section id={SectionContainerTypes.EMPOWER}>
    <Heading type="article" className="text-center m-y-6">
      {Strings.empower.heading}
    </Heading>
    <div className="text-center">
      <WebpImage
        src="/static/img/garden.webp"
        fallback="/static/img/garden.jpg"
        alt="A beautiful garden of flowers"
        className="m-bottom-6 width-70"
      />
    </div>
    <BlockQuote
      content={Strings.empower.quote.caption}
      author={Strings.empower.quote.author}
    />
    {Strings.empower.body.map((text) => (
      <Paragraph key={text}>
        <FormattedHTMLString>{text}</FormattedHTMLString>
      </Paragraph>
    ))}
    <div className="m-top-6">
      {auth.hasWriteAccess && <NewArticleButton />}
      <BlogPreview title="Stories" blog={blog} />
    </div>
  </section>
);

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  blog: getBlog(state),
});

export default compose<any>(
  makeBlogLoaderHOC(BlogTypes.EMPOWER),
  connect(mapStateToProps)
)(EmpowerInspireContainer);
