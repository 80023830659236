import * as React from 'react';
import ErrorOverlay from '../error/ErrorOverlay';

interface NotFoundContainerProps {}

const NotFoundContainer = (props: NotFoundContainerProps) => {
  return <ErrorOverlay message="The page you requested does not exist :(" />;
};

export default NotFoundContainer;
