import * as React from 'react';
import classNames from 'classnames';

interface HeadingProps {
  type?:
    | 'article'
    | 'subsection-small'
    | 'subsection-large'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6';
  underline?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Heading = ({ type, className, underline, children }: HeadingProps) => {
  const combinedClassName = classNames({
    'header-underline': Boolean(underline),
    [className]: Boolean(className)
  });

  switch (type) {
    case 'article':
      return (
        <h2 className={`article-header ${combinedClassName}`}>{children}</h2>
      );
    case 'subsection-small':
      return (
        <h4 className={`subsection-heading-small ${combinedClassName}`}>
          {children}
        </h4>
      );
    case 'subsection-large':
      return (
        <h4 className={`subsection-heading-large ${combinedClassName}`}>
          {children}
        </h4>
      );
    case 'h1':
      return <h1 className={className}>{children}</h1>;
    case 'h2':
      return <h2 className={className}>{children}</h2>;
    case 'h3':
      return <h3 className={className}>{children}</h3>;
    case 'h4':
      return <h4 className={className}>{children}</h4>;
    case 'h5':
      return <h5 className={className}>{children}</h5>;
    case 'h6':
      return <h6 className={className}>{children}</h6>;
    default:
      return <div>{children}</div>;
  }
};

export default Heading;
