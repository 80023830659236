import { EditableListItem } from '../types';
import assert from '../util/assert';

export default class ListAPI {
  static fetchList = ({ listName }: { listName: string }) => {
    return fetch(`/api/v1/list/${listName}`);
  };

  static createListItem = ({
    listName,
    listItem,
  }: {
    listName: string;
    listItem: EditableListItem;
  }) => {
    assert(
      listName.length > 0,
      'Cannot create list item with empty `listName`'
    );

    return fetch(`/api/v1/list-items/${listName}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(listItem.toJS()),
    });
  };

  static updateListItem = ({ listItem }: { listItem: EditableListItem }) => {
    return fetch(`/api/v1/list-items`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(listItem.toJS()),
    });
  };

  static deleteListItem = ({
    listName,
    listItemId,
  }: {
    listName: string;
    listItemId: number;
  }) => {
    assert(
      listName.length > 0,
      'Cannot delete list item with empty `listName`'
    );
    assert(
      typeof listItemId === 'number',
      `Invalid listItemId specified. Expected number, got \`${listItemId}\``
    );

    return fetch(`/api/v1/list-items/${listName}/${listItemId}`, {
      method: 'DELETE',
    });
  };
}
