import * as React from 'react';

import List from './List';
import {
  EditableListItem as EditableListItemType,
  EditableList as EditableListType,
} from '../../types';
import Button from '../button/Button';
import Icon from '../images/Icon';
import asEditableField from '../form/EditableField';
import LinkInput from '../form/LinkInput';
import LinkListItem, { ListItemValue } from './LinkListItem';

const EditableListItem = asEditableField(
  LinkListItem,
  'value',
  LinkInput,
  (newValue: ListItemValue, oldValue: ListItemValue) =>
    newValue.href !== oldValue.href || newValue.label !== oldValue.label
);

const defaultProps = { canEdit: false };

const makeProtocolPrefixed = (link: string) => {
  return link.startsWith('http://') || link.startsWith('https://')
    ? link
    : `http://${link}`;
};

interface EditableListProps extends React.PropsWithoutRef<typeof defaultProps> {
  list: EditableListType;
  onItemAdd: () => void;
  onItemSave: (listItem: EditableListItemType) => void;
  onItemDelete: (listItemId: number) => void;
}

const EditableList = ({
  canEdit,
  list: { items },
  onItemAdd,
  onItemSave,
  onItemDelete,
}: EditableListProps) => {
  return (
    <List>
      {items.map((item: EditableListItemType) => (
        <EditableListItem
          key={item.id}
          canEdit={canEdit}
          onSave={({ href, label }: ListItemValue) =>
            onItemSave(
              item.set('label', label).set('href', makeProtocolPrefixed(href))
            )
          }
          onDelete={() => onItemDelete(item.id)}
          value={{ href: item.href, label: item.label }}
          type="arrow"
        />
      ))}
      {canEdit && (
        <Button className="m-y-0 m-x-auto" onClick={onItemAdd}>
          <Icon name="edit" className="m-right-2" />
          <span>New item</span>
        </Button>
      )}
    </List>
  );
};

EditableList.defaultProps = defaultProps;

export default EditableList;
