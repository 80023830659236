import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Article, Editor } from '../../../types';
import { updateEditor } from '../../../actions/EditorActions';
import {
  getUpdateArticleRequestStatus,
  getEditor,
} from '../../../selectors/selectors';
import { RequestStatus } from '../../../lib/requests/RequestTypes';

import Button from '../../../lib/button/Button';
import { withAuth } from '../../hocs/withAuth';

import Icon from '../../../lib/images/Icon';
import ActionToolbar from './header/ActionToolbar';
import Tooltip from '../../../lib/text/Tooltip';

import ArticleContent from './ArticleContent';
import { BlogArticleHeader, ArticleHeaderProps } from './header/ArticleHeaders';
import LoaderOverlay from '../../../lib/util/LoaderOverlay';
import ErrorOverlay from '../../error/ErrorOverlay';
import { updateArticle, createArticle } from '../../../actions/APIActions';
import ArticleDisplayContainer from '../../wrappers/ArticleDisplayContainer';
import { ArticleContentEditorProps } from './ArticleContentEditor';

interface BlogArticleProps {
  article: Article;
  editor: Editor;
  updateStatus: RequestStatus;
  updateEditor: (
    editor: Editor | Partial<{ [k in keyof Editor]: any }>
  ) => void;
  updateArticle: ({ article }: { article: Article }) => void;
  createArticle: ({
    article,
    isTemplate,
  }: {
    article: Article;
    isTemplate: boolean;
  }) => void;
  hasWriteAccess: boolean;
  HeaderComponent?: React.ElementType<ArticleHeaderProps>;
  contentEditorProps?: Partial<ArticleContentEditorProps>;
}

const BlogArticle = ({
  article,
  editor,
  updateStatus,
  updateEditor,
  updateArticle,
  createArticle,
  hasWriteAccess = false,
  HeaderComponent = BlogArticleHeader,
  contentEditorProps = {},
}: BlogArticleProps) => {
  const { isEditing } = editor;
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (updateStatus === RequestStatus.PENDING) {
      setIsLoading(true);
    }

    if (updateStatus === RequestStatus.SUCCEEDED) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [updateStatus, isLoading]);

  const saveIcon = isEditing ? 'save' : 'pencil-alt';

  const editorToolbar = hasWriteAccess && (
    <ActionToolbar
      condensed={contentEditorProps.hasEditableHeader && isEditing}
    >
      {isEditing && (
        <Tooltip text="Cancel">
          <Button
            onClick={() => {
              updateEditor({ isEditing: false });
            }}
          >
            <Icon name="ban" />
          </Button>
        </Tooltip>
      )}
      <Tooltip
        text={isEditing ? 'Save' : 'Edit'}
        disabled={isEditing && !editor.hasChanged}
      >
        <Button
          disabled={isLoading || (isEditing && !editor.hasChanged)}
          onClick={() => {
            updateEditor({ isEditing: !isEditing });

            if (isEditing) {
              const newArticle = article.withMutations((mutable) => {
                if (editor.rawContent !== null) {
                  mutable.set('rawContent', editor.rawContent);
                }

                // Check if the user has changed the title
                if (editor.title) {
                  mutable.set('title', editor.title);
                }

                // If a thumbnail has been uploaded, attach it to the article
                if (editor.thumbnailFile) {
                  mutable.set('thumbnailFile', editor.thumbnailFile);
                }

                // If the current thumbnail has been removed, null out the thumbnailId
                if (editor.thumbnailFile === null) {
                  mutable.set('thumbnailId', null);
                }

                // If an audio file has been uploaded, attach it to the article
                if (editor.audioFile) {
                  mutable.set('audioFile', editor.audioFile);
                }

                // If an audio file has been removed, null out the thumbnailId
                if (editor.audioFile === null) {
                  mutable.set('audioFile', null);
                }

                return mutable;
              });

              // Issue a POST instead of PUT if the article has no id yet/
              // Thould should only occur if we are editing the template article for the first time

              if (newArticle.id === null) {
                createArticle({
                  article: newArticle,
                  isTemplate: true,
                });
              } else {
                updateArticle({
                  article: newArticle,
                });
              }
            }
          }}
        >
          <Icon name={saveIcon} />
        </Button>
      </Tooltip>
    </ActionToolbar>
  );

  return (
    <ArticleDisplayContainer>
      <Prompt
        when={isEditing}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {HeaderComponent && !isEditing ? (
        <HeaderComponent article={article}>{editorToolbar}</HeaderComponent>
      ) : (
        <div className="relative" style={{ top: -70 }}>
          {editorToolbar}
        </div>
      )}
      <LoaderOverlay
        hasLoaded={!isLoading}
        hasError={updateStatus === RequestStatus.FAILED}
        FallbackComponent={() => (
          <ErrorOverlay message="There was a problem updating this article. Please refresh and try again" />
        )}
      >
        <ArticleContent
          isEditing={isEditing}
          article={article}
          contentEditorProps={contentEditorProps}
        />
      </LoaderOverlay>
    </ArticleDisplayContainer>
  );
};

const mapStateToProps = (state) => ({
  editor: getEditor(state),
  updateStatus: getUpdateArticleRequestStatus(state),
});

const mapDispatchToProps = {
  updateArticle,
  updateEditor,
  createArticle,
};

export default compose<any>(
  withAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(BlogArticle);
