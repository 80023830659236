// String utils

export const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const makeUUID = (seed?: string) => {
  const epochMS = new Date().getTime();
  if (seed) {
    return Array.from(seed)
      .reduce(
        (randomized, remainder) => randomized + remainder.charCodeAt(0),
        epochMS
      )
      .toString();
  }

  return epochMS.toString();
};
