import * as React from 'react';
import { getWindowWidth, getWindowHeight } from './windowWatcher';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    height: getWindowHeight(),
    width: getWindowWidth(),
  });

  const handleResize = () => {
    const [width, height] = [getWindowWidth(), getWindowHeight()];

    if (width !== windowSize.width || height !== windowSize.height) {
      setWindowSize({ width, height });
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
