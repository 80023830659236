import styled from 'styled-components';

const ArticlePreviewContentWrapper = styled.div`
  position: relative;
  margin-bottom: 0 !important;
  max-height: 150px;
  overflow-y: hidden;
`;

export default ArticlePreviewContentWrapper;
