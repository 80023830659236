import * as React from 'react';
import styled from 'styled-components';

import Heading from '../../lib/text/Heading';
import { MediaQueries } from '../../lib/constants/ScreenSizes';

const InfoPanelContainer = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  flex-basis: 1;
  border-top: solid 1px black;

  @media ${MediaQueries.SMALL_DESKTOP} {
    margin-left: 0;
    margin-right: 0;
  }

  @media ${MediaQueries.DESKTOP} {
    border-top: none;

    &:not(:first-child) {
      border-left: solid 1px black;
    }
  }
`;
InfoPanelContainer.displayName = 'InfoPanelContainer';

const PanelBodyContainer = styled.div`
  margin-bottom: 16px;
  margin-left: 15px;
  margin-right: 15px;

  @media ${MediaQueries.DESKTOP} {
    height: 100%;
  }
`;
PanelBodyContainer.displayName = 'PanelBodyContainer';

const InfoPanel = ({ className, title, children }) => {
  return (
    <InfoPanelContainer className={className}>
      <Heading type="subsection-small" className="text-center">
        {title}
      </Heading>
      <PanelBodyContainer>{children}</PanelBodyContainer>
    </InfoPanelContainer>
  );
};

export default InfoPanel;
