import * as React from 'react';
import styled from 'styled-components';

import Image from './Image';
import Icon from './Icon';

const EmptyImagePreviewContainer = styled.div`
  background-color: #eee;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: ${({ width, height }) =>
    `${(height - 33) / 2}px ${(width - 22) / 2}px`};
`;

const CONTAINER_HEIGHT = 100;
const CONTAINER_WIDTH = 100;

interface UploadImagePreviewProps {
  src?: string;
}

const UploadImagePreview = ({ src }: UploadImagePreviewProps) => {
  if (!src) {
    return (
      <EmptyImagePreviewContainer
        width={CONTAINER_WIDTH}
        height={CONTAINER_HEIGHT}
      >
        <Icon name="image" />
      </EmptyImagePreviewContainer>
    );
  }

  return (
    <Image
      src={src}
      style={{
        width: CONTAINER_WIDTH,
        height: CONTAINER_HEIGHT,
        objectFit: 'contain',
        boxSizing: 'unset', // So the border doesn't count as the inner content
        border: '1px solid #c4c4c4',
        borderRadius: 2,
      }}
    />
  );
};

export default UploadImagePreview;
