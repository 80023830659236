import * as React from 'react';

import { getWindowWidth } from './windowWatcher';
import ScreenSizes from '../constants/ScreenSizes';

type StyleSizeDeclarations = {
  [screenSize in ScreenSizes]?: React.CSSProperties;
};

const isCompositeStyleObject = (
  styles: StyleSizeDeclarations | React.CSSProperties
): styles is StyleSizeDeclarations => {
  return 992 in styles || 768 in styles || 481 in styles || 0 in styles;
};

const useStyles = (
  styles: StyleSizeDeclarations | React.CSSProperties
): React.CSSProperties => {
  if (!isCompositeStyleObject(styles)) {
    return styles;
  }

  const windowWidth = getWindowWidth();

  if (windowWidth >= ScreenSizes.DESKTOP_UP) {
    return {
      ...styles[ScreenSizes.MOBILE_UP],
      ...styles[ScreenSizes.TABLET_UP],
      ...styles[ScreenSizes.SMALL_DESKTOP_UP],
      ...styles[ScreenSizes.DESKTOP_UP],
    };
  }

  if (windowWidth >= ScreenSizes.SMALL_DESKTOP_UP) {
    return {
      ...styles[ScreenSizes.MOBILE_UP],
      ...styles[ScreenSizes.TABLET_UP],
      ...styles[ScreenSizes.SMALL_DESKTOP_UP],
    };
  }

  if (windowWidth >= ScreenSizes.TABLET_UP) {
    return {
      ...styles[ScreenSizes.MOBILE_UP],
      ...styles[ScreenSizes.TABLET_UP],
    };
  }

  return styles[ScreenSizes.MOBILE_UP] || {};
};

export default useStyles;
