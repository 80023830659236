import * as React from 'react';
import styled from 'styled-components';

import AudioPlayer from './AudioPlayer';
import Icon from '../images/Icon';

const EmptyAudioPreviewContainer = styled.div`
  background-color: #eee;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: ${({ width, height }) =>
    `${(height - 33) / 2}px ${(width - 22) / 2}px`};
`;

const CONTAINER_HEIGHT = 100;
const CONTAINER_WIDTH = 100;

interface UploadAudioPreviewProps {
  src?: string;
}

const UploadAudioPreview = ({ src }: UploadAudioPreviewProps) => {
  if (!src) {
    return (
      <EmptyAudioPreviewContainer
        width={CONTAINER_WIDTH}
        height={CONTAINER_HEIGHT}
      >
        <Icon name="volume-up" />
      </EmptyAudioPreviewContainer>
    );
  }

  return (
    <AudioPlayer
      // Prevent the click from bubbling up into the upload container
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      src={src}
      style={{
        width: CONTAINER_WIDTH,
        height: CONTAINER_HEIGHT,
        objectFit: 'contain',
        boxSizing: 'unset', // So the border doesn't count as the inner content
        border: '1px solid #c4c4c4',
        borderRadius: 2,
      }}
    />
  );
};

export default UploadAudioPreview;
