import * as React from 'react';
import JsxParser from 'react-jsx-parser';
import List from '../list/List';
import ListItem from '../list/ListItem';
import Link from './Link';

interface FormattedHTMLStringProps extends React.PropsWithChildren<{}> {
  children: string;
}

const FormattedHTMLString = ({ children }: FormattedHTMLStringProps) => {
  return (
    <JsxParser
      components={{
        b: ({ children }) => <b style={{ fontWeight: 800 }}>{children}</b>,
        em: ({ children }) => <em>{children}</em>,
        li: ({ children }) => <ListItem>{children}</ListItem>,
        ul: ({ children }) => <List>{children}</List>,
        a: ({ children, href }) => <Link href={href}>{children}</Link>,
      }}
      jsx={children}
      renderInWrapper={false}
    />
  );
};

export default FormattedHTMLString;
