import * as React from 'react';
import Button from '../button/Button';
import Icon from '../images/Icon';

interface PlayerButtonProps {
  playing: boolean;
  onClick: () => void;
}

const PlayerButton = ({ playing, onClick }: PlayerButtonProps) => {
  return (
    <Button
      onClick={onClick}
      purpose="meta"
      style={{ fontSize: 32, margin: 'auto' }}
    >
      <Icon name={playing ? 'pause-circle' : 'play-circle'} />
    </Button>
  );
};

export default PlayerButton;
