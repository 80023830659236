import * as React from 'react';
import { connect } from 'react-redux';

import ContentEditor from '../ContentEditor';
import { Article } from '../../../types';
import HeaderInput from './header/HeaderInput';
import FileUploader, {
  ACCEPTABLE_AUDIO,
  ACCEPTABLE_IMAGES,
} from '../../../lib/form/FileUploader';
import UploadImagePreview from '../../../lib/images/UploadImagePreview';
import UploadAudioPreview from '../../../lib/audio/UploadAudioPreview';

export interface ArticleContentEditorProps {
  article: Article;
  onThumbnailUpload: (file: File) => void;
  onThumbnailRemove: () => void;
  onAudioUpload: (file: File) => void;
  onAudioRemove: () => void;
  onCleanup?: () => void;
  onHeaderChange?: (title: string) => void;
  allowThumbnails?: boolean;
  allowAudioUpload?: boolean;
  hasEditableHeader?: boolean;
}

const ArticleContentEditor = ({
  article,
  onThumbnailUpload,
  onThumbnailRemove,
  onAudioUpload,
  onAudioRemove,
  onCleanup,
  onHeaderChange,
  allowThumbnails = true,
  allowAudioUpload = false,
  hasEditableHeader = false,
}: ArticleContentEditorProps) => {
  // Cleanup editor on unmount
  React.useEffect(() => {
    return onCleanup;
  }, []);

  return (
    <div>
      {hasEditableHeader && (
        <HeaderInput initialValue={article.title} onChange={onHeaderChange} />
      )}
      {allowThumbnails && (
        <FileUploader
          className="m-y-3"
          initialSrc={article.thumbnailId && `/files/${article.thumbnailId}`}
          onUpload={onThumbnailUpload}
          onRemove={onThumbnailRemove}
          uploadText="Click here to upload a thumbnail"
          removeText="Remove current image"
          PreviewComponent={UploadImagePreview}
          acceptableExtensions={ACCEPTABLE_IMAGES}
        />
      )}
      {allowAudioUpload && (
        <FileUploader
          className="m-y-3"
          initialSrc={article.audioId && `/files/${article.audioId}`}
          onUpload={onAudioUpload}
          onRemove={onAudioRemove}
          uploadText="Click here to upload audio"
          removeText="Remove current audio"
          PreviewComponent={UploadAudioPreview}
          acceptableExtensions={ACCEPTABLE_AUDIO}
        />
      )}
      <ContentEditor articleContent={article.content} />
    </div>
  );
};

export default ArticleContentEditor;
