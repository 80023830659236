const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96];

export const ELEMENT_REPLACEMENTS = {
  a: {
    tagName: 'Link',
  },
  // So div tags always wrap image tags for some reason.. and when centered
  // sets the style to `text-align: none` for whatever reason. So we map that css
  // property here, but could be a cause of confusion later if we need to actually
  // set `text-align` to `none` for whatever reason
  div: {
    tagName: 'div',
    styleMappings: {
      'text-align': {
        none: { 'text-align': 'center' },
      },
    },
  },
  p: {
    tagName: 'Paragraph',
    styleMappings: {
      'text-align': {
        left: { align: 'left' },
        center: { align: 'center' },
        right: { align: 'right' },
      },
    },
  },
  span: {
    tagName: 'span',
    styleMappings: {
      'font-size': {
        // Add a font size replacement for each available font size (to scale with smaller screens)
        ...FONT_SIZES.reduce(
          (replacements, fontSize) => ({
            ...replacements,
            [`${fontSize}px`]: {
              'font-size': `${parseFloat((fontSize / 18).toFixed(2))}em`,
            },
          }),
          {}
        ),
      },
    },
  },
  ul: {
    tagName: 'List',
  },
  li: {
    tagName: 'ListItem',
  },
  // img: {
  //   tagName: 'Image'
  // }
};
