import * as React from 'react';

import NavDropdown from './NavDropdown';

interface NavItemProps {
  text: string;
  href?: string;
  dropdownContent?: JSX.Element;
}

class NavItem extends React.PureComponent<NavItemProps, {}> {
  static defaultProps: NavItemProps;

  makeClassName() {
    const { dropdownContent } = this.props;
    return `nav-item${dropdownContent ? ' nav-dropdown' : ''}`;
  }

  render() {
    const { text, href, dropdownContent } = this.props;

    return (
      <div className={this.makeClassName()}>
        <a href={href} className="nav-link">
          {text}
        </a>
        {dropdownContent}
      </div>
    );
  }
}

NavItem.defaultProps = {
  text: ''
};

export default NavItem;
