import * as React from 'react';
import Link from '../text/Link';
import ListItem, { ListItemProps } from './ListItem';

export type ListItemValue = { href: string; label: string };

interface LinkListItemProps extends ListItemProps {
  value: ListItemValue;
}

const LinkListItem = ({ value, ...rest }: LinkListItemProps) => {
  return (
    <ListItem {...rest}>
      <Link href={value.href}>{value.label}</Link>
    </ListItem>
  );
};

export default LinkListItem;
