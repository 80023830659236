import * as React from 'react';
import classNames from 'classnames';

interface BoxProps {
  grow: number;
  shrink: number;
  order?: number;
  basis?: string | number;
  alignSelf?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

class Box extends React.PureComponent<BoxProps, {}> {
  static defaultProps: BoxProps;

  makeClassName() {
    const { alignSelf, className } = this.props;

    return classNames({
      'align-self-start': alignSelf === 'start',
      'align-self-end': alignSelf === 'end',
      'align-self-center': alignSelf === 'center',
      'align-self-baseline': alignSelf === 'baseline',
      'align-self-stretch': alignSelf === 'stretch',
      [className]: Boolean(className)
    });
  }

  render() {
    const { grow, shrink, order, basis } = this.props;

    const style = {
      flexGrow: grow,
      flexShrink: shrink,
      ...(order && { flexOrder: order }),
      ...(basis && { flexBasis: basis })
    };
    return (
      <div style={style} className={this.makeClassName()}>
        {this.props.children}
      </div>
    );
  }
}

Box.defaultProps = {
  grow: 0,
  shrink: 1
};

export default Box;
