import * as React from 'react';

import Flex from '../../lib/box/Flex';
import Paragraph from '../../lib/text/Paragraph';

const ErrorOverlay = ({ message }) => {
  return (
    <Flex alignItems="center" justifyContent="center" className="error-overlay">
      <Paragraph type="soft">{message}</Paragraph>
    </Flex>
  );
};

ErrorOverlay.defaultProps = {
  message: 'There was a problem loading. Please try again',
};

export default ErrorOverlay;
