import * as React from 'react';

import Footer from '../lib/footer/Footer';
import FooterItem from '../lib/footer/FooterItem';

const BlogFooter = () => {
  return (
    <Footer>
      <FooterItem
        text="© 2023 MARY-LAURI BARTOLA"
        tooltip="Made with &#9829; by Jesse"
      />
      <FooterItem text="|" />
      <FooterItem text="ABOUT" url="/about" />
      <FooterItem text="|" />
      <FooterItem text="CONTACT" url="/contact" />
    </Footer>
  );
};

export default BlogFooter;
