import * as React from 'react';
import classNames from 'classnames';

interface LinkProps {
  href: string;
  title?: string;
  type?: string;
  className?: string;
  target?: string;
  underline: boolean;
  disabled?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
}

class Link extends React.Component<LinkProps, {}> {
  static defaultProps: LinkProps;

  getStyles() {
    if (this.props.disabled) {
      return {
        color: '#747474',
        pointerEvents: 'none' as 'none', // wtf lol
      };
    }

    return {};
  }

  render() {
    const {
      href,
      title,
      type,
      className,
      onClick,
      target,
      disabled,
      underline,
      children,
    } = this.props;

    const onClickHandler = onClick
      ? (e) => {
          // Stop the link from shooting us to the top of the screen
          e.preventDefault();

          // Stop the link from triggering the upload browser
          e.stopPropagation();
          onClick(e);
        }
      : null;

    const allClasses = classNames({
      'animate-link': type === 'animate',
      underline: Boolean(!disabled && underline),
      'no-underline': Boolean(underline) === false,
      [className]: Boolean(className),
    });

    return (
      <a
        href={href}
        title={title}
        className={allClasses}
        {...(target && { target })}
        {...(disabled && { disabled, style: this.getStyles() })}
        {...(onClick && { onClick: onClickHandler })}
      >
        {children}
      </a>
    );
  }
}

Link.defaultProps = {
  underline: true,
  href: '#',
};

export default Link;
