import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import {
  DeleteListItemActionTypes,
  UpdateListItemActionTypes,
  CreateListItemActionTypes,
  fetchList,
} from '../actions/APIActions';
import { ActionWithPayloadData } from '../types';

type ListItemUpdateTypes =
  | typeof CreateListItemActionTypes.RECEIVE
  | typeof UpdateListItemActionTypes.RECEIVE
  | typeof DeleteListItemActionTypes.RECEIVE;
type ListItemUpdatePayloadData = { listName: string };

const RefetchListEpic = (action$) =>
  action$.pipe(
    ofType(
      CreateListItemActionTypes.RECEIVE,
      UpdateListItemActionTypes.RECEIVE,
      DeleteListItemActionTypes.RECEIVE
    ),
    map(
      (
        action: ActionWithPayloadData<
          ListItemUpdateTypes,
          ListItemUpdatePayloadData
        >
      ) => fetchList({ listName: action.payload.data.listName })
    )
  );

export default combineEpics(RefetchListEpic);
