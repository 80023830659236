import * as React from 'react';

import TextInput from '../../../../lib/form/TextInput';

interface HeaderInputProps {
  onChange: (articleTitle) => void;
  initialValue?: string;
}

const HeaderInput = ({ onChange, initialValue }: HeaderInputProps) => {
  return (
    <div className="width-80 m-x-auto m-bottom-5">
      <TextInput
        type="underline"
        placeholder="Your Title Here"
        className="text-center"
        initialValue={initialValue}
        onChange={({ target: { value } }) => onChange(value)}
        size={34}
      />
    </div>
  );
};

export default HeaderInput;
