import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import EditableList from '../../lib/list/EditableList';
import {
  EditableList as EditableListType,
  EditableListItem,
} from '../../types';
import { withAuth } from '../hocs/withAuth';
import {
  makeGetListByName,
  getFetchListRequestStatus,
} from '../../selectors/selectors';
import { makeLoaderHOC, LoaderDep } from '../../lib/util/LoaderHOC';
import {
  fetchList,
  createListItem,
  updateListItem,
  deleteListItem,
} from '../../actions/APIActions';
import ErrorOverlay from '../error/ErrorOverlay';

interface ListDisplayContainerProps {
  hasWriteAccess: boolean;
  listName: string;
  list: EditableListType;
  createListItem: ({ listName, listItem }) => void;
  updateListItem: ({ listItem }) => void;
  deleteListItem: ({ listName, listItemId }) => void;
}

const ListDisplayContainer = ({
  hasWriteAccess,
  list,
  createListItem,
  updateListItem,
  deleteListItem,
}: ListDisplayContainerProps) => {
  // Needed for the epic update cycle
  if (!list) {
    return null;
  }

  return (
    <EditableList
      canEdit={hasWriteAccess}
      list={list}
      onItemAdd={() =>
        createListItem({
          listName: list.name,
          listItem: new EditableListItem({ label: '' }),
        })
      }
      onItemSave={(listItem) => updateListItem({ listItem })}
      onItemDelete={(listItemId) =>
        deleteListItem({ listName: list.name, listItemId })
      }
    />
  );
};

const mapStateToProps = (state, { listName }) => ({
  list: makeGetListByName(listName)(state),
});

const mapDispatchToProps = {
  createListItem,
  updateListItem,
  deleteListItem,
};

export default compose<React.ElementType<Partial<ListDisplayContainerProps>>>(
  makeLoaderHOC<ListDisplayContainerProps>({
    loadDeps: ({ listName }) => [
      new LoaderDep(getFetchListRequestStatus, () => fetchList({ listName })),
    ],
    ErrorComponent: ErrorOverlay,
    useLoadingState: false,
  }),
  withAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(ListDisplayContainer);
