import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import rootEpic from './epics/RootEpic';
import rootReducer from './reducers/RootReducer';

const epicMiddleware = createEpicMiddleware();

export default function configureStore(initialState?) {
  const middlewareEnhancer = applyMiddleware(thunkMiddleware, epicMiddleware);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  epicMiddleware.run(rootEpic);

  return store;
}
