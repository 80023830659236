import { combineEpics, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { CreateArticleActionTypes } from '../actions/APIActions';
import BlogConfig from '../constants/BlogConfig';
import { ActionWithPayloadData, Article } from '../types';

type NewArticleRequestArgsType = { article: Article; isTemplate: boolean };

const NewArticleRedirectEpic = (action$) =>
  action$.pipe(
    ofType(CreateArticleActionTypes.RECEIVE),
    tap(
      (
        action: ActionWithPayloadData<
          typeof CreateArticleActionTypes.RECEIVE,
          Article,
          NewArticleRequestArgsType
        >
      ) => {
        // We want to avoid redirecting if we're on a creation flow from the template article

        if (!action.payload.fetchArgs.isTemplate) {
          const article = action.payload.fetchArgs.article;
          const blogPathname = BlogConfig[article.blogAlias].pathname;

          // Redirect to respective url 3 seconds after article has been created
          setTimeout(
            () =>
              (window.location.href = `/${blogPathname}/articles/${article.alias}`),
            3000
          );
        }
      }
    ),
    mapTo(EMPTY) // Note: This gives a redux error since EMPTY is not an action, but that's fine \shrug
  );

export default combineEpics(NewArticleRedirectEpic);
