import * as React from 'react';

import { useDropzone } from 'react-dropzone';
import Flex from '../box/Flex';
import Paragraph from '../text/Paragraph';
import Link from '../text/Link';
import Icon from '../images/Icon';

export const ACCEPTABLE_IMAGES = ['image/jpeg', 'image/png', 'image/gif'];
export const ACCEPTABLE_AUDIO = ['audio/mpeg', 'audio/wav'];

const DEFAULT_ACCEPTABLE_FILES = [...ACCEPTABLE_IMAGES, ...ACCEPTABLE_AUDIO];

const defaultFileUploaderProps = {
  uploadText: 'Click here or drag and drop a file to upload',
  removeText: 'Remove current file',
  acceptableExtensions: DEFAULT_ACCEPTABLE_FILES,
};
type DefaultFileUploaderPropType = typeof defaultFileUploaderProps;

interface FileUploaderProps extends DefaultFileUploaderPropType {
  onUpload: (file: File) => void;
  className?: string;
  initialSrc?: string;
  onRemove?: () => void;
  PreviewComponent?: React.ElementType<{ src?: string }>;
}

const FileUploader = ({
  initialSrc,
  uploadText,
  removeText,
  onUpload,
  className,
  onRemove,
  PreviewComponent,
  acceptableExtensions,
}: FileUploaderProps) => {
  const [previewSrc, setPreviewSrc] = React.useState(initialSrc);

  const onDrop = React.useCallback((acceptedFiles) => {
    // Only use the first file, multiple doesn't make sense
    const uploadedFile = acceptedFiles[0];

    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      const fileSrc = reader.result;
      setPreviewSrc(fileSrc as string); // Should type check
      onUpload(uploadedFile);
    };
    reader.readAsDataURL(uploadedFile);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptableExtensions,
  });

  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      <Flex>
        {PreviewComponent && <PreviewComponent src={previewSrc} />}
        <div className="m-left-4">
          <Paragraph type="info" size="large">
            <Icon name="cloud-upload-alt" className="m-right-2" />
            {uploadText}
          </Paragraph>
          <Paragraph type="info" size="large">
            <Link
              className="block"
              disabled={!previewSrc}
              onClick={() => {
                setPreviewSrc(null);

                if (onRemove) {
                  onRemove();
                }
              }}
            >
              <Icon name="ban" className="m-right-3" />
              {removeText}
            </Link>
          </Paragraph>
        </div>
      </Flex>
    </div>
  );
};

FileUploader.defaultProps = defaultFileUploaderProps;

export default FileUploader;
