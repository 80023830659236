import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import { List } from 'immutable';

import { getAlerts } from '../../selectors/selectors';
import { closeAlert } from '../../actions/AlertActions';
import { AlertItem } from '../../types';
import AlertToastItem from './AlertToastItem';

interface AlertContainerProps {
  alerts: List<AlertItem>;
  closeAlert: ({ index }: { index: number }) => void;
  className?: string;
}

/**
 * Intended to be placed at the top-level of this application to show toast alerts
 */
const AlertContainer = ({
  alerts,
  closeAlert,
  className,
}: AlertContainerProps) => {
  return (
    <div
      className={className}
      style={{
        position: 'fixed',
        width: '50vw',
        left: '50%',
        transform: 'translate(-50%)',
        zIndex: 999999,
      }}
    >
      <CSSTransitionGroup
        transitionName="alertFade"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {alerts.map((alert, i) => (
          <AlertToastItem
            key={alert.id}
            type={alert.alertType}
            message={alert.message}
            onClose={() => closeAlert({ index: i })}
          />
        ))}
      </CSSTransitionGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alerts: getAlerts(state),
});

const mapDispatchToProps = {
  closeAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
