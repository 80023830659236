import * as React from 'react';
import { connect } from 'react-redux';

import DraftContent from '../../shared/DraftContent';
import { getEditor } from '../../../selectors/selectors';
import { updateEditor } from '../../../actions/EditorActions';
import { Editor as EditorType, Article } from '../../../types';
import ArticleContentEditor, {
  ArticleContentEditorProps,
} from './ArticleContentEditor';

interface ArticleContentProps {
  editor: EditorType;
  article: Article;
  updateEditor: (updates: Partial<EditorType>) => void;
  contentEditorProps: Partial<ArticleContentEditorProps>;
}

const ArticleContent = ({
  editor,
  article,
  updateEditor,
  contentEditorProps,
}: ArticleContentProps) => {
  return (
    <div>
      {editor.isEditing ? (
        <ArticleContentEditor
          article={article}
          onThumbnailUpload={(file) =>
            updateEditor({
              hasChanged: true,
              thumbnailFile: file,
            })
          }
          onThumbnailRemove={() =>
            updateEditor({
              hasChanged: !(
                !article.thumbnailId &&
                editor.thumbnailFile &&
                (editor.rawContent === null ||
                  editor.rawContent === article.rawContent)
              ),
              thumbnailFile: null,
            })
          }
          onCleanup={() => {
            updateEditor({
              hasChanged: false,
              rawContent: null,
              thumbnailFile: undefined,
              audioFile: undefined,
            });
          }}
          onHeaderChange={(newTitle) => {
            updateEditor({
              hasChanged: newTitle !== article.title,
              title: newTitle,
            });
          }}
          onAudioUpload={(file) =>
            updateEditor({ hasChanged: true, audioFile: file })
          }
          onAudioRemove={() =>
            updateEditor({
              hasChanged: !(
                !article.audioId &&
                editor.audioFile &&
                (editor.rawContent === null ||
                  editor.rawContent === article.rawContent)
              ),
              audioFile: null,
            })
          }
          {...contentEditorProps}
        />
      ) : (
        <DraftContent html={article.content} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  editor: getEditor(state),
});

const mapDispatchToProps = {
  updateEditor,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleContent);
