import { Article } from '../types';

export const toAlias = (name) => encodeURIComponent(name);

export const mapArticle = (articleJS) => {
  const { date, rawContent } = articleJS;

  return new Article({
    ...articleJS,
    date: date && new Date(`${date.slice(0, -6)}Z`),
    rawContent,
  });
};
