import * as React from 'react';
import classNames from 'classnames';

interface FlexProps {
  direction: string;
  wrap: 'wrap' | 'nowrap' | 'reverse';
  justifyContent:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems:
    | 'baseline'
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end';
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

class Flex extends React.PureComponent<FlexProps, {}> {
  static defaultProps: FlexProps;

  makeClassName() {
    const {
      direction,
      wrap,
      justifyContent,
      alignItems,
      className,
    } = this.props;

    return classNames({
      flex: true,
      'flex-row': direction === 'row',
      'flex-column': direction === 'column',
      'flex-row-reverse': direction === 'row-reverse',
      'flex-column-reverse': direction === 'column-reverse',
      nowrap: wrap === 'nowrap',
      wrap: wrap === 'wrap',
      'wrap-reverse': wrap === 'reverse',
      'justify-flex-start': justifyContent === 'start',
      'justify-flex-end': justifyContent === 'end',
      'justify-flex-center': justifyContent === 'center',
      'justify-space-between': justifyContent === 'space-between',
      'justify-space-around': justifyContent === 'space-around',
      'justify-space-evenly': justifyContent === 'space-evenly',
      'align-flex-start': alignItems === 'start',
      'align-flex-end': alignItems === 'end',
      'align-flex-center': alignItems === 'center',
      'align-stretch': alignItems === 'stretch',
      'align-baseline': alignItems === 'baseline',
      ...(className && { [className]: true }),
    });
  }

  render() {
    return <div className={this.makeClassName()}>{this.props.children}</div>;
  }
}

Flex.defaultProps = {
  direction: 'row',
  wrap: 'nowrap',
  justifyContent: 'start',
  alignItems: 'stretch',
};

export default Flex;
