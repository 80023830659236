import { createSelector } from 'reselect';

import {
  FetchBlogActionTypes,
  FetchArticlesActionTypes,
  FetchAuthActionTypes,
  UpdateArticleActionTypes,
  FetchListActionTypes,
  CreateArticleActionTypes,
} from '../actions/APIActions';
import { RequestStatus } from '../lib/requests/RequestTypes';
import { Article } from '../types';

const getStore = (state) => state.store;
const getRequestStatuses = (state) => state.requestStatuses;

export const makeRequestSelector = (REQUEST: string) => {
  return createSelector(
    getRequestStatuses,
    (requestStatuses) =>
      requestStatuses.get(REQUEST) || RequestStatus.UNINITIALIZED
  );
};

export const getBlog = createSelector(getStore, (store) => store.get('blog'));

export const getAuth = createSelector(getStore, (store) => store.get('auth'));

export const getEditor = createSelector(getStore, (store) =>
  store.get('editor')
);

export const getAlerts = createSelector(getStore, (store) =>
  store.get('alerts')
);

export const makeGetListByName = (listName) =>
  createSelector(getStore, (store) => store.getIn(['lists', listName]));

// For one-off sections (i.e., pages that aren't a "blog" with multiple articles) there will only be a single article
// with a blog alias equal to the sectionId. For new pages, we should provide a fallback so the page doesn't blow up
export const makeGetArticleByAlias = (articleAlias) =>
  createSelector(
    getStore,
    (store) =>
      store.getIn(['blog', 'articles']).find((_) => _.alias === articleAlias) ||
      Article.placeholder(articleAlias)
  );

export const getBlogRequestStatus = makeRequestSelector(
  FetchBlogActionTypes.REQUEST
);

export const getArticleRequestStatus = makeRequestSelector(
  FetchArticlesActionTypes.REQUEST
);

export const getAuthRequestStatus = makeRequestSelector(
  FetchAuthActionTypes.REQUEST
);

export const getUpdateArticleRequestStatus = makeRequestSelector(
  UpdateArticleActionTypes.REQUEST
);

export const getCreateArticleRequestStatus = makeRequestSelector(
  CreateArticleActionTypes.REQUEST
);

export const getFetchListRequestStatus = makeRequestSelector(
  FetchListActionTypes.REQUEST
);
