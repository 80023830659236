import * as React from 'react';
import Paragraph from '../../lib/text/Paragraph';
import Section from '../../lib/box/Section';

const EmptyArticle = () => {
  return (
    <Section className="empty-article text-center" width="large">
      <Paragraph type="soft">
        There are no posts here yet. Check back later!
      </Paragraph>
    </Section>
  );
};

export default EmptyArticle;
