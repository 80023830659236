import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';

import { makeLoaderHOC, LoaderDep } from '../lib/util/LoaderHOC';

import { wrapIndexPage } from './Index';
import ErrorOverlay from './error/ErrorOverlay';
import { fetchAuth } from '../actions/APIActions';
import { getAuthRequestStatus } from '../selectors/selectors';
import CollegeConsultingContainer from './containers/CollegeConsultingContainer';
import LoaderOverlay from '../lib/util/LoaderOverlay';
import NotFoundContainer from './containers/NotFoundContainer';
import VoiceContainer from './containers/VoiceContainer';
import EmpowerInspireContainer from './containers/EmpowerInspireContainer';
import ProjectManagementContainer from './containers/ProjectManagementContainer';
import CareerConsultingContainer from './containers/CareerConsultingContainer';
import { BlogTypes } from '../types';
import {
  ABOUT,
  ADMIN,
  BLOG,
  BLOG_ARTICLE,
  BLOG_CREATE,
  COLLEGE_CONSULTING,
  EMPOWER_INSPIRE,
  EMPOWER_INSPIRE_ARTICLE,
  EMPOWER_INSPIRE_CREATE,
  PROJECT_MANAGEMENT,
  ROOT,
  CONTENT_CREATION,
  CAREER_CONSULTING,
} from '../constants/Routes';

// Declare our code splitting by route container
const HomeContainer = loadable(() =>
  import(/* webpackChunkName: "HomeContainer" */ './containers/HomeContainer')
);
const AdminContainer = loadable(() =>
  import(/* webpackChunkName: "AdminContainer" */ './containers/AdminContainer')
);
const AboutContainer = loadable(() =>
  import(/* webpackChunkName: "AboutContainer" */ './containers/AboutContainer')
);
const BlogContainer = loadable(() =>
  import(/* webpackChunkName: "Blog" */ './blog/BlogContainer')
);
const ArticleContainer = loadable(() =>
  import(/* webpackChunkName: "Article" */ './blog/article/ArticleContainer')
);
const NewArticleContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "NewArticle" */ './blog/article/new/NewArticleContainer'
    ),
  {
    fallback: (
      <LoaderOverlay
        FallbackComponent={null}
        hasError={false}
        hasLoaded={false}
      />
    ),
  }
);

const HomePage = wrapIndexPage(HomeContainer);
const About = wrapIndexPage(AboutContainer, {
  includeThreePanelFooter: true,
  showAboutMeFooter: false,
});
const CollegeConsultingPageContainer = wrapIndexPage(
  CollegeConsultingContainer
);
const CareerConsultingPageContainer = wrapIndexPage(CareerConsultingContainer);
const BlogPageContainer = wrapIndexPage(BlogContainer, {
  includeThreePanelFooter: false,
});
const CreateBlogArticleContainer = wrapIndexPage(
  () => <NewArticleContainer blogAlias={BlogTypes.BLOG} />,
  {
    includeThreePanelFooter: false,
  }
);
const CreateEmpowerArticleContainer = wrapIndexPage(
  () => <NewArticleContainer blogAlias={BlogTypes.EMPOWER} />,
  {
    includeThreePanelFooter: false,
  }
);
const BlogArticleContainer = wrapIndexPage(({ match }) => (
  <ArticleContainer
    blogAlias={BlogTypes.BLOG}
    allowAudioUpload={true}
    articleAlias={encodeURIComponent(
      decodeURIComponent(match.params.articleAlias)
    )}
  />
));
const EmpowerArticleContainer = wrapIndexPage(({ match }) => (
  <ArticleContainer
    blogAlias={BlogTypes.EMPOWER}
    allowThumbnails={false} // TODO: Use BlogConfig for this
    allowAudioUpload={true}
    articleAlias={encodeURIComponent(
      decodeURIComponent(match.params.articleAlias)
    )}
  />
));
const VoicePageContainer = wrapIndexPage(VoiceContainer);
const EmpowerInspirePageContainer = wrapIndexPage(EmpowerInspireContainer);
const ProjectManagementPageContainer = wrapIndexPage(
  ProjectManagementContainer
);
const PageNotFoundContainer = wrapIndexPage(NotFoundContainer, {
  includeThreePanelFooter: false,
});

/** App will be the main container for the entire front end **/
const App = () => (
  <Switch>
    <Route exact path={ROOT} component={HomePage} />
    <Route exact path={ADMIN} component={AdminContainer} />
    <Route exact path={ABOUT} component={About} />
    <Route
      exact
      path={COLLEGE_CONSULTING}
      component={CollegeConsultingPageContainer}
    />
    <Route
      exact
      path={CAREER_CONSULTING}
      component={CareerConsultingPageContainer}
    />
    <Route exact path={BLOG} component={BlogPageContainer} />
    <Route exact path={BLOG_CREATE} component={CreateBlogArticleContainer} />
    <Route path={BLOG_ARTICLE} component={BlogArticleContainer} />
    <Route
      exact
      path={EMPOWER_INSPIRE}
      component={EmpowerInspirePageContainer}
    />
    <Route
      exact
      path={EMPOWER_INSPIRE_CREATE}
      component={CreateEmpowerArticleContainer}
    />
    <Route path={EMPOWER_INSPIRE_ARTICLE} component={EmpowerArticleContainer} />
    <Route exact path={CONTENT_CREATION} component={VoicePageContainer} />
    <Route
      exact
      path={PROJECT_MANAGEMENT}
      component={ProjectManagementPageContainer}
    />
    <Route component={PageNotFoundContainer} />
  </Switch>
);

export default makeLoaderHOC<{}>({
  loadDeps: [new LoaderDep(getAuthRequestStatus, fetchAuth)],
  ErrorComponent: ErrorOverlay,
})(App);
