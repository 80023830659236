import * as React from 'react';

import TextInput from './TextInput';
import Flex from '../box/Flex';
import Button from '../button/Button';
import Icon from '../images/Icon';
import {
  AbstractInputComponentProps,
  AbstractSyntheticEvent,
} from '../../types';
import Box from '../box/Box';

interface EditableFieldProps extends React.PropsWithChildren<{}> {
  onSave: (newValue: any) => void;
  onDelete?: () => void;
  canEdit: boolean;
}

const asEditableField = <
  P,
  V,
  Q extends AbstractInputComponentProps<
    V,
    AbstractSyntheticEvent<V>
  > = AbstractInputComponentProps<V, AbstractSyntheticEvent<V>>
>(
  WrappedDisplayComponent: React.ComponentType<P>,
  valuePropName: string,
  InputComponent: React.ComponentType<
    Q
  > = (TextInput as unknown) as React.ComponentType<Q>,
  areValuesDifferent: (nv: V, ov: V) => boolean = (newValue: V, oldValue: V) =>
    newValue !== oldValue
) => {
  const EditableFieldController = (props: P & EditableFieldProps) => {
    // `inheritedValue` is the value that would've been displayed if this wasn't an editable input
    const inheritedValue: V = props[valuePropName];
    const { canEdit, onSave, onDelete } = props;

    const [editedValue, setEditedValue] = React.useState(inheritedValue);
    const [isEditing, setIsEditing] = React.useState(false);

    const hasMadeChanges = areValuesDifferent(editedValue, inheritedValue);

    const onEditChange = () => {
      if (hasMadeChanges) {
        onSave(editedValue);
      }
      setIsEditing((_) => !_);
    };
    const onEditCancel = () => {
      setEditedValue(inheritedValue);
      setIsEditing(false);
    };

    return (
      <Flex justifyContent="space-between">
        <Box grow={0.5}>
          {canEdit && isEditing ? (
            <InputComponent
              {...({
                value: editedValue,
                onChange: ({ target: { value } }) => setEditedValue(value),
              } as Q)}
            />
          ) : (
            <WrappedDisplayComponent
              {...props}
              {...{ [valuePropName]: editedValue }}
            />
          )}
        </Box>
        <Flex className="width-initial">
          {canEdit && !isEditing && onDelete && (
            <Button onClick={onDelete} purpose="meta">
              <Icon name="trash" />
            </Button>
          )}
          {isEditing && hasMadeChanges && (
            <Button onClick={onEditCancel} purpose="meta">
              <Icon name="ban" />
            </Button>
          )}
          {canEdit && (
            <Button onClick={onEditChange} purpose="meta">
              <Icon
                name={
                  isEditing ? (hasMadeChanges ? 'save' : 'ban') : 'pencil-alt'
                }
              />
            </Button>
          )}
        </Flex>
      </Flex>
    );
  };

  return EditableFieldController;
};

export default asEditableField;
