import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Article, SectionContainerTypes } from '../../types';
import BlogArticle from '../blog/article/BlogArticle';
import {
  getArticleRequestStatus,
  makeGetArticleByAlias,
} from '../../selectors/selectors';
import { LoaderDep, makeLoaderHOC } from '../../lib/util/LoaderHOC';
import { fetchArticles } from '../../actions/APIActions';
import ErrorOverlay from '../error/ErrorOverlay';
import { ArticleHeaderProps } from '../blog/article/header/ArticleHeaders';
import { ArticleContentEditorProps } from '../blog/article/ArticleContentEditor';

type SectionMakerArgs = {
  sectionType: SectionContainerTypes;
  HeaderComponent: React.ComponentType<ArticleHeaderProps>;
  preContent?: React.ReactNode;
  contentEditorProps?: Partial<ArticleContentEditorProps>;
};

const makeSectionContainer = ({
  sectionType,
  HeaderComponent,
  preContent = null,
  contentEditorProps = {},
}: SectionMakerArgs) => {
  interface SectionContainerProps {
    article: Article;
  }

  const getArticleBySectionType = makeGetArticleByAlias(sectionType);

  const SectionContainer = ({ article }: SectionContainerProps) => {
    return (
      <section id={sectionType}>
        {preContent}
        <BlogArticle
          article={article}
          HeaderComponent={HeaderComponent}
          contentEditorProps={{ allowThumbnails: false, ...contentEditorProps }}
        />
      </section>
    );
  };

  SectionContainer.displayName = `SectionContainer(type='${sectionType}')`;

  const mapStateToProps = (state) => ({
    article: getArticleBySectionType(state),
  });

  return compose<
    React.ComponentType<SectionContainerProps & { match: string }>
  >(
    makeLoaderHOC<SectionContainerProps>({
      loadDeps: [
        new LoaderDep(getArticleRequestStatus, () =>
          fetchArticles({ options: { blog_alias: sectionType } })
        ),
      ],
      ErrorComponent: ErrorOverlay,
    }),
    connect(mapStateToProps)
  )(SectionContainer);
};

export default makeSectionContainer;
