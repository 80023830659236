import * as React from 'react';

import Link from '../text/Link';

interface FooterItemProps {
  text: string;
  tooltip?: string;
  url?: string;
}

class FooterItem extends React.Component<FooterItemProps, {}> {
  renderTextItem() {
    const { text, tooltip, url } = this.props;

    if (url) {
      return (
        <Link href={url} title={tooltip} type="animate" underline={false}>
          {text}
        </Link>
      );
    }

    return <p title={tooltip}>{text}</p>;
  }

  render() {
    return <div className="footer-item">{this.renderTextItem()}</div>;
  }
}

export default FooterItem;
