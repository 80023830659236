import * as React from 'react';
import styled from 'styled-components';

import Paragraph from '../../lib/text/Paragraph';

import InfoPanel from './InfoPanel';
import Strings from '../../Strings';
import FormattedHTMLString from '../../lib/text/FormattedHTMLString';
import Flex from '../../lib/box/Flex';
import IconRow from './IconRow';
import useWindowSize from '../../lib/util/useWindowSize';
import ScreenSizes, { MediaQueries } from '../../lib/constants/ScreenSizes';
import ListDisplayContainer from '../shared/ListDisplayContainer';

const ThreePanelContainer = styled.div`
  margin-top: 50px;

  @media ${MediaQueries.TABLET} {
    margin-bottom: 20px;
  }

  @media ${MediaQueries.DESKTOP} {
    margin-top: 75px;
    margin-bottom: 20px;
  }
`;

interface ThreePanelProps {
  showAboutMe?: boolean;
}

const ThreePanel = ({ showAboutMe = true }: ThreePanelProps) => {
  const { width: windowWidth } = useWindowSize();

  const panelClassName =
    'p-all-0 ' + (showAboutMe ? 'col-lg-4' : 'col-lg-6') + ' col-md-12';

  return (
    <ThreePanelContainer className="row">
      {showAboutMe && (
        <InfoPanel className={panelClassName} title="MEET ML">
          <Paragraph size="small">
            <FormattedHTMLString>{Strings.footer.aboutML}</FormattedHTMLString>
          </Paragraph>
        </InfoPanel>
      )}
      <InfoPanel className={panelClassName} title="BLOGS I LIKE">
        <ListDisplayContainer listName="likedBlogs" />
      </InfoPanel>
      <InfoPanel className={panelClassName} title="CONTACT &amp; FOLLOW">
        <Flex
          direction={windowWidth < ScreenSizes.DESKTOP_UP ? 'row' : 'column'}
          wrap="wrap"
          justifyContent="space-around"
          className="social-icons m-y-4"
        >
          {Strings.footer.socialIcons.icons.map((iconProps, i) => (
            <IconRow key={i} {...iconProps} />
          ))}
        </Flex>
      </InfoPanel>
    </ThreePanelContainer>
  );
};

export default ThreePanel;
