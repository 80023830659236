import { LoaderDep, makeLoaderHOC } from '../../lib/util/LoaderHOC';
import { fetchBlog } from '../../actions/APIActions';
import { getBlogRequestStatus } from '../../selectors/selectors';
import ErrorOverlay from '../error/ErrorOverlay';

export const makeBlogLoaderHOC = <InnerProps>(blogName: string) =>
  makeLoaderHOC<InnerProps>({
    loadDeps: [new LoaderDep(getBlogRequestStatus, () => fetchBlog(blogName))],
    ErrorComponent: ErrorOverlay,
  });
