import * as React from 'react';

import ResponsiveNavbar from '../lib/nav/ResponsiveNavbar';
import NavItem from '../lib/nav/NavItem';
import NavDropdown from '../lib/nav/NavDropdown';
import {
  ABOUT,
  BLOG,
  CAREER_CONSULTING,
  COLLEGE_CONSULTING,
  EMPOWER_INSPIRE,
  PROJECT_MANAGEMENT,
  ROOT,
  CONTENT_CREATION,
} from '../constants/Routes';

interface MainNavbarProps {}

class MainNavbar extends React.Component<MainNavbarProps, {}> {
  render() {
    return (
      <ResponsiveNavbar resizeAt={768}>
        <NavItem text="HOME" href={ROOT} />
        <NavItem text="CONTENT CREATION" href={CONTENT_CREATION} />
        <NavItem text="PROJECT MANAGEMENT" href={PROJECT_MANAGEMENT} />
        <NavItem
          text="COACHING"
          href="#"
          dropdownContent={
            <NavDropdown>
              <NavItem text="CAREER" href={CAREER_CONSULTING} />
              <NavItem text="COLLEGE" href={COLLEGE_CONSULTING} />
            </NavDropdown>
          }
        />
        <NavItem text="EMPOWER &amp; INSPIRE" href={EMPOWER_INSPIRE} />
        <NavItem text="BLOG" href={BLOG} />
        <NavItem text="ABOUT ML" href={ABOUT} />
      </ResponsiveNavbar>
    );
  }
}

export default MainNavbar;
