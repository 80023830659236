import * as React from 'react';
import JsxParser from 'react-jsx-parser';

import Paragraph from '../../lib/text/Paragraph';
import ListItem from '../../lib/list/ListItem';
import Image from '../../lib/images/Image';
import Link from '../../lib/text/Link';
import List from '../../lib/list/List';

const DraftContent = ({ html }) => {
  return (
    <div className="ck-content">
      <JsxParser
        components={{
          Paragraph,
          ListItem,
          Image,
          List,
          Link,
        }}
        jsx={html}
        onError={(e) => console.error(e)}
        showWarnings={true}
      />
    </div>
  );
};

export default DraftContent;
