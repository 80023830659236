import * as React from 'react';
import styled from 'styled-components';

import Flex from '../../../../lib/box/Flex';
import ActionToolbarItem from '../ActionToolbarItem';

const ActionToolbarContainer = styled.div`
  position: absolute;
  right: 0;
  top: ${({ condensed }) => (condensed ? '60px' : '20px')};
  width: 160px;
`;
interface ActionToolbarProps extends React.PropsWithChildren<{}> {
  alignItems: 'space-around' | 'end';
  condensed?: boolean;
}

const ActionToolbar = ({
  alignItems,
  condensed = false,
  children,
}: ActionToolbarProps) => {
  return (
    <ActionToolbarContainer condensed={condensed}>
      <Flex justifyContent={alignItems}>
        {React.Children.map(children, (child) => (
          <ActionToolbarItem>{child}</ActionToolbarItem>
        ))}
      </Flex>
    </ActionToolbarContainer>
  );
};

ActionToolbar.defaultProps = {
  alignItems: 'space-around',
};

export default ActionToolbar;
