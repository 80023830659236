import * as React from 'react';

interface PlayerBarProps {
  currentTime: number;
  duration: number;
}

const formatMinuteTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.round(time) % 60;

  const secondsLabel = seconds < 10 ? `0${seconds}` : seconds.toString();

  return `${minutes}:${secondsLabel}`;
};

const PlayerBar = ({ currentTime, duration }: PlayerBarProps) => {
  const percentCompleted = (currentTime / duration) * 100;
  return (
    <div>
      <div
        style={{
          position: 'relative',
          height: 8,
          width: '90%',
          borderRadius: 3,
          margin: 'auto',
          background: `linear-gradient(to right, black ${percentCompleted}%, #eee 0)`,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: -2,
            left: `${percentCompleted}%`,
            width: 2,
            height: 12,
            background: 'black',
          }}
        />
      </div>
      <div className="text-center">{formatMinuteTime(currentTime)}</div>
    </div>
  );
};

export default PlayerBar;
