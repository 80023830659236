import * as React from 'react';
import { WithClassname } from '../../types';
import Flex from '../../lib/box/Flex';

const IconRow = ({
  className,
  href,
  label,
}: WithClassname<{ href: string; label: string }>) => {
  return (
    <Flex alignItems="center">
      <i className={className} />
      <a href={href}>{label}</a>
    </Flex>
  );
};

export default IconRow;
