/** Defines global Typescript type definitions **/

import moment from 'moment';
import { Action } from 'redux';
import { Record, List, Map } from 'immutable';

import { fixSelfClosingTags } from './util/parsing/parser';
import Strings from './Strings';

export type ActionWithPayloadData<T, D, F = any, P = {}, M = {}> = Action<T> & {
  payload: { data: D; fetchArgs?: F; requestPayload?: P };
  meta?: M;
};
export type WithClassname<T> = T & { className: string };
export type AbstractSyntheticEvent<T> = { target: { value: T } };
export interface AbstractInputComponentProps<
  T,
  E extends AbstractSyntheticEvent<T>
> {
  value?: T;
  onChange?: (evt: E) => void;
}

export class Blog extends Record({
  alias: null,
  displayName: null,
  articles: List(),
}) {
  alias: string;
  displayName: string;
  articles: List<Article>;
}

interface ArticleBase {
  id: number;
  alias: string;
  title: string;
  date: Date;
  rawContent: string;
  blogAlias: string;
  thumbnailId?: string;
  thumbnailFile?: File;
  audioId?: string;
  audioFile?: File;
}

export class Article extends Record<ArticleBase>({
  id: null,
  alias: null,
  title: null,
  date: null,
  rawContent: '',
  blogAlias: null,
  thumbnailId: null,
  thumbnailFile: null,
  audioId: null,
  audioFile: null,
}) {
  memoizedContent = null;

  static placeholder(alias: string) {
    return new Article({
      title: Strings.placeholderArticle.title,
      alias,
      blogAlias: alias,
      rawContent: Strings.placeholderArticle.content,
    });
  }

  toJS() {
    // Exclude the thumbnail file and audio file since they don't need to be serialized
    return {
      id: this.id,
      alias: this.alias,
      title: this.title,
      date: this.date,
      blogAlias: this.blogAlias,
      rawContent: this.rawContent,
      thumbnailId: this.thumbnailId,
      audioId: this.audioId,
    };
  }

  get content() {
    if (this.memoizedContent) {
      return this.memoizedContent;
    }
    return (this.memoizedContent = fixSelfClosingTags(this.rawContent, [
      'img',
    ]));
  }

  get readableDate() {
    return moment(this.date).format('dddd, MMMM Do YYYY');
  }
}

export class Auth extends Record({
  hasWriteAccess: false,
  hasError: false,
}) {
  hasWriteAccess: boolean;
  hasError: boolean;
}

export class Editor extends Record({
  isEditing: false,
  hasChanged: false,
  rawContent: null,
  thumbnailFile: undefined, // undefined signifies no change, null signifies removal
  audioFile: undefined, // undefined signifies no change, null signifies removal
  title: null,
}) {
  isEditing: boolean;
  hasChanged: boolean;
  rawContent: string;
  thumbnailFile: File;
  audioFile: File;
  title: string;
}

export enum AlertType {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

export class AlertItem extends Record({
  id: null,
  message: null,
  alertType: null,
}) {
  id: string;
  message: string;
  alertType: AlertType;
}

export class EditableList extends Record({
  name: null,
  items: null,
}) {
  name: string;
  items: List<EditableListItem>;
}

export class EditableListItem extends Record({
  id: null,
  label: null,
  href: null,
}) {
  id: number;
  label: string;
  href: string;
}

export enum SectionContainerTypes {
  HOME = 'home',
  VOICE = 'voice', // Aliased in the navbar as "Content Creation"
  EMPOWER = 'empower-inspire',
  PROJECT_MANAGEMENT = 'project-management',
  CAREER_CONSULTING = 'career-consulting',
}

export enum BlogTypes {
  BLOG = 'main',
  EMPOWER = 'empower-inspire',
}
